import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Sentry from '@sentry/react';
import * as Yup from 'yup';
import { selectUserInfor } from '../../feature/eduIntro/eduIntro';
import Input from '../Input/Input';
import api from '../../services';

function Problem() {
  const history = useHistory();
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
    phone: Yup.string()
      .required('This field is required')
      .matches(/\(\d{3}\) \d{3}-\d{4}/, 'Enter your phone number'),
    email: Yup.string().required('This field is required').email('Please enter a valid email address'),
  });

  const initialValues = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  };

  type FormData = typeof initialValues;

  try {
    const selectUsers = useSelector(selectUserInfor);
    initialValues.firstName = selectUsers.firstName;
    initialValues.lastName = selectUsers.lastName;
    initialValues.phone = selectUsers.phone;
    initialValues.email = selectUsers.email;
  } catch { } // eslint-disable-line

  const onSubmit = async (values: FormData, helpers: FormikHelpers<FormData>) => {
    helpers.setSubmitting(true);

    try {
      await api.leadApi.submitLead(values);
    } catch (err) {
      Sentry.captureException(err);
    }

    history.push('/thank-you');
  };

  return (
    <main className="--main --info">
      <div className="grid-container">
        <h1>You&apos;re one step closer to getting your&nbsp;quote.</h1>
        <p style={{ textAlign: 'center' }}>Just review the information below and someone will get in&nbsp;touch!</p>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ errors, handleBlur, handleChange, isSubmitting, isValid, touched, values }) => (
            <Form id="swell-ecom-tool" className="swell-ecom-tool">
              <fieldset>
                <div className="__info flex --wrap">
                  <div>
                    <Input
                      label="First Name"
                      htmlFor="firstName"
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      id="firstName"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                      errorText={((touched.firstName && errors.firstName) || '') as string}
                      nameClass={touched.firstName && errors.firstName ? '--error' : ''}
                    />
                  </div>
                  <div>
                    <Input
                      label="Last Name"
                      htmlFor="lastName"
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      id="lastName"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorText={((touched.lastName && errors.lastName) || '') as string}
                      nameClass={touched.lastName && errors.lastName ? '--error' : ''}
                      value={values.lastName}
                    />
                  </div>
                  <div>
                    <Input
                      label="Email"
                      htmlFor="email"
                      type="email"
                      name="email"
                      placeholder="Email"
                      id="email"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      errorText={((touched.email && errors.email) || '') as string}
                      nameClass={touched.email && errors.email ? '--error' : ''}
                    />
                  </div>
                  <div>
                    <Input
                      label="Phone"
                      htmlFor="phone"
                      type="tel"
                      name="phone"
                      placeholder="Phone"
                      id="phone"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      errorText={((touched.phone && errors.phone) || '') as string}
                      nameClass={touched.phone && errors.phone ? '--error' : ''}
                      mask="(999) 999-9999"
                    />
                  </div>
                </div>
              </fieldset>
              <p className="--note --center">Rest assured, this info is kept private and we will not spam you.</p>

              <div className="menu-form">
                <input
                  className={`submit btn --submit ${isSubmitting || !isValid ? '' : '--valid'}`}
                  type="submit"
                  value="Contact Me about my Options"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </main>
  );
}

export default Problem;
