import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { addUserInfor, selectUserInfor } from '../../feature/eduIntro/eduIntro';
import { IUserInfor } from '../../reducer/typing';
import Input from '../Input/Input';
import { getContactInfoHeader } from '../../AppTheme';
import { getContactInfoConsentText, getTermsComponent } from '../../AppThemeComponents';

const HeaderText = getContactInfoHeader();
const ConsentText = getContactInfoConsentText();

function ContactInfo() {
  const selectUsers = useSelector(selectUserInfor);

  const dispatch = useDispatch();
  const history = useHistory();

  const [buttonStatus, setButtonStatus] = useState('');
  const [userInfor, setUserInfo] = useState<IUserInfor>(selectUsers);

  useEffect(() => {
    setUserInfo(selectUsers);
  }, [selectUsers]);

  const validate = (values: any) => {
    const errors = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      contactAgree: '',
    };
    if (values?.firstName === '') {
      errors.firstName = 'This field is required.';
    }
    if (values?.lastName === '') {
      errors.lastName = 'This field is required.';
    }
    if (values?.phone === '') {
      errors.phone = 'This field is required.';
    }
    if (values?.email === '') {
      errors.email = 'This field is required.';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values?.email)) {
      errors.email = 'Please enter a valid email address.';
    }

    if (!values?.contactAgree) {
      errors.contactAgree = 'This field is required.';
    }

    return errors;
  };

  useEffect(() => {
    let count = 0;
    const errorsValue = validate(userInfor);
    Object.keys(errorsValue).forEach((item) => {
      if ((errorsValue as any)[item] === '') {
        count += 1;
      }
    });

    if (count === 5) {
      setButtonStatus('--continue');
    } else {
      setButtonStatus('--error');
    }
  }, [userInfor]);

  const formik = useFormik({
    initialValues: {
      ...selectUsers,
    },
    validate,
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === 'checkbox') {
      setUserInfo({ ...userInfor, [e.target.name]: e.target.checked });
    } else {
      setUserInfo({ ...userInfor, [e.target.name]: e.target.value });
    }
  };

  const handleClick = async (e: any) => {
    e.preventDefault();
    if (buttonStatus === '--error' || buttonStatus === '') {
      return;
    }
    dispatch(
      addUserInfor({
        ...userInfor,
      })
    );

    // Dispatch an event to SFDC live chat to prefill fields
    const setDetailsEvent = new CustomEvent('persistInfoForPrechat', {
      detail: {
        name: `${userInfor.firstName} ${userInfor.lastName}`,
        email: userInfor.email,
      },
    });

    document.dispatchEvent(setDetailsEvent);

    try {
      history.push('/address');
    } catch (error) {
      Sentry.captureException(error);
      history.push('/problem');
    }
  };

  return (
    <main className="--main --info">
      <div className="grid-container">
        <h1>{HeaderText}</h1>
        <h1>First, we need some info.</h1>
        <form id="swell-ecom-tool" className="swell-ecom-tool --grid-mtop" noValidate>
          <fieldset>
            <div className="__info flex --wrap">
              <div>
                <Input
                  label="First Name"
                  htmlFor="firstName"
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  id="firstName"
                  required
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleChange(e);
                  }}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    handleChange(e);
                  }}
                  value={userInfor?.firstName}
                  errorText={((formik.touched.firstName && formik.errors.firstName) || '') as string}
                  nameClass={formik.touched.firstName && formik.errors.firstName ? '--error' : ''}
                />
              </div>
              <div>
                <Input
                  label="Last Name"
                  htmlFor="lastName"
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  id="lastName"
                  required
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleChange(e);
                  }}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    handleChange(e);
                  }}
                  errorText={((formik.touched.lastName && formik.errors.lastName) || '') as string}
                  nameClass={formik.touched.lastName && formik.errors.lastName ? '--error' : ''}
                  value={userInfor?.lastName}
                />
              </div>
              <div>
                <Input
                  label="Email"
                  htmlFor="email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  id="email"
                  required
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleChange(e);
                  }}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    handleChange(e);
                  }}
                  value={userInfor?.email}
                  errorText={((formik.touched.email && formik.errors.email) || '') as string}
                  nameClass={formik.touched.email && formik.errors.email ? '--error' : ''}
                />
              </div>
              <div>
                <Input
                  label="Phone Number"
                  htmlFor="phone"
                  type="tel"
                  name="phone"
                  placeholder="Phone Number"
                  id="phone"
                  required
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleChange(e);
                  }}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    handleChange(e);
                  }}
                  value={userInfor?.phone}
                  errorText={((formik.touched.phone && formik.errors.phone) || '') as string}
                  nameClass={formik.touched.phone && formik.errors.phone ? '--error' : ''}
                  mask="(999) 999-9999"
                />
              </div>
              <div className="--contact-me-checkbox">
                <div style={{ width: '16px', height: '16px', padding: '0', margin: '4px 4px 0 0', flexShrink: 0 }}>
                  <input
                    name="contactAgree"
                    id="contactAgree"
                    type="checkbox"
                    checked={formik.values.contactAgree}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value="true"
                  />
                </div>
                <label htmlFor="contactAgree">{ConsentText}</label>
              </div>
              <div className="--contact-me-checkbox" style={{ paddingTop: 0 }}>
                {formik.errors.contactAgree && (
                  <label id="firstname-error" className="--error">
                    {formik.errors.contactAgree}
                  </label>
                )}
              </div>
            </div>
          </fieldset>

          {getTermsComponent()}

          <div className="menu-form">
            <Link className="btn --back" to="/">
              <span className="icon-back" />
            </Link>
            <input
              className={`submit btn --submit ${buttonStatus}`}
              type="submit"
              value="Continue"
              onClick={handleClick}
              onMouseEnter={() => formik.validateForm(formik.values)}
            />
            <div className="btn --restart" style={{ width: '18px', cursor: 'unset' }} />
          </div>
        </form>
      </div>
    </main>
  );
}

export default ContactInfo;
