import { getProblemUTMContent } from '../AppTheme';

const submitLead = async (args: { firstName: string; lastName: string; email: string; phone: string }) => {
  const submitData: any = {};
  const now = new Date();
  const ampm = now.getUTCHours() > 12 ? 'PM' : 'AM';
  submitData.date_submitted = [now.toISOString().substring(0, 10)];
  submitData.time_submitted = [`${now.getUTCHours() - (ampm === 'PM' ? 12 : 0)}:${now.getUTCMinutes()} ${ampm} UTC`];
  submitData.record_type = ['0124M000000MeAM'];
  submitData.utmContent = [getProblemUTMContent()];
  submitData.company = ['N/A'];
  submitData.lead_source = ['Swell Marketing'];
  submitData.page_url = [window.location.origin + window.location.pathname];
  submitData.audit_url_full = [document.location.toString()];
  submitData.audit_url_referrer = [document.referrer];
  submitData.audit_user_agent = [window.navigator?.userAgent || ''];
  submitData.street_address = [''];
  submitData.city = [''];
  submitData.state = [''];
  submitData.zip_code = [''];
  submitData.home_size = [''];
  submitData.utility_company = [''];
  submitData.lead_source_account = [''];
  submitData.lead_source_medium = [''];
  submitData.ip_address = [''];
  submitData.energy_program = [''];
  submitData.variant = [''];
  submitData.notes = [''];
  submitData.utmCampaign = [''];

  submitData.property_owner = [false];
  submitData.utility_customer = [false];
  submitData.existing_solar = [false];
  submitData.want_solar = [false];
  submitData.interested_in_solar_and_battery = [false];
  submitData.interested_in_battery = [false];
  submitData.email_from_utility = [false];

  submitData.name = [`${args.firstName} ${args.lastName}`];
  submitData.phone_number = [args.phone];
  submitData.email = [args.email];

  const requestArgs: RequestInit = {
    headers: { 'content-type': 'application/json; charset=UTF-8' },
    body: `data.json=${JSON.stringify(submitData)}`,
    method: 'POST',
    mode: 'cors',
  };

  const fullUrl = process.env.REACT_APP_LEAD_SUBMIT_ENDPOINT || '';
  const response = await fetch(fullUrl, requestArgs);

  if (!response.ok || response.status !== 200) {
    throw new Error(`Lead submit failed, OK: ${response.ok}, Status: ${response.status}`);
  }

  const responseObj = await response.json();
  if (!responseObj || !responseObj.id || !responseObj.success) {
    throw new Error(
      `Lead submit failed, ${responseObj} ${responseObj.id} ${responseObj.success} ${JSON.stringify(responseObj)}`
    );
  }
};

export default { submitLead };
