import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Popover from 'react-popover';
import CircleLogo from '../../assets/img/penfed/footer-logo.png';

function PFFooter() {
  const history = useHistory();
  const location = useLocation();
  const [sunnyVisible, setSunnyVisible] = useState(false);

  const showHowToPopover = false;
  const howToPopoverBody = [<p>Need help? More info down here if you need anything!</p>];
  const getSlideUrl = (index: number) => `edu-topics?from=${encodeURIComponent(history.location.pathname)}#${index}`;

  const phoneRaw = process.env.REACT_APP_CONTACT_PHONE_NUMBER || '';
  const phoneParts = /1?[ -]?\(?(\d{3})\)?[ -]?(\d{3})[ -]?(\d{4})/.exec(phoneRaw);
  let phoneUnformatted = '';
  let phoneFormatted = '';

  if (phoneParts && phoneParts.length > 0) {
    phoneUnformatted = `1${phoneParts[1]}${phoneParts[2]}${phoneParts[3]}`;
    phoneFormatted = `(${phoneParts[1]}) ${phoneParts[2]}-${phoneParts[3]}`;
  }

  return (
    <>
      <Popover body={howToPopoverBody} isOpen={showHowToPopover}>
        {/* eslint-disable-next-line */}
        <div key="a" className="footer__sunny --click-action" onClick={() => setSunnyVisible(!sunnyVisible)}>
          <img src={CircleLogo} alt="Get Help" />
        </div>
      </Popover>
      <div className={`menu-sunny --edu ${sunnyVisible ? '--sunny-active' : ''}`}>
        <div className="__container">
          <div className="grid-row">
            <div className="col-xs-12 col-md-6">
              <Link to={getSlideUrl(0)} className="__link" onClick={() => setSunnyVisible(false)}>
                From the old grid to a new energy future
              </Link>
            </div>
            <div className="col-xs-12 col-md-6">
              <Link to={getSlideUrl(1)} className="__link" onClick={() => setSunnyVisible(false)}>
                The new/better way to power your life
              </Link>
            </div>
            <div className="col-xs-12 col-md-6">
              <Link to={getSlideUrl(2)} className="__link" onClick={() => setSunnyVisible(false)}>
                Savings over time
              </Link>
            </div>
            <div className="col-xs-12 col-md-6">
              <Link to={getSlideUrl(4)} className="__link" onClick={() => setSunnyVisible(false)}>
                Joining the Smart Grid
              </Link>
            </div>
          </div>
          <div className="grid-row --contact-row">
            <div className="col-xs-12 col-no-margin">
              <a href={`tel:${phoneUnformatted}`}>
                Call us at&nbsp;
                <span>{phoneFormatted}</span>
              </a>
              <a href={process.env.REACT_APP_BOOK_APPOINTMENT_LINK}>Book a meeting to talk later</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PFFooter;
