import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as Sentry from '@sentry/react';
import rootReducer from './rootReducer';
import { Theme } from '../AppTheme';

const persistConfig = {
  key: `userInfo__${Theme}`,
  storage,
  whitelist: ['userInfor', 'userAddress', 'recommendation'],
};
const pReducer = persistReducer(persistConfig, rootReducer);

const sentryReduxEnhancer = Sentry.createReduxEnhancer();
const sentryEnvironment = process.env.REACT_APP_SENTRY_ENVIRONMENT;

const store = configureStore({
  reducer: pReducer,
  middleware: (getDefaultMiddleware) => {
    if (sentryEnvironment === 'development') {
      return getDefaultMiddleware().concat(logger);
    }

    return getDefaultMiddleware();
  },
  enhancers: [sentryReduxEnhancer],
});
const persistor = persistStore(store, null, () => {
  try {
    const { firstName, lastName, email } = store.getState()?.userInfor;

    if (firstName && lastName && email) {
      // Dispatch an event to SFDC live chat to prefill fields
      const setDetailsEvent = new CustomEvent('persistInfoForPrechat', {
        detail: {
          name: `${firstName} ${lastName}`,
          email: email,
        },
      });

      document.dispatchEvent(setDetailsEvent);
    }
  } catch (err) {
    Sentry.captureException(err);
  }
});

export { persistor, store };
