import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { selectUserAddress } from '../../feature/address/address';
import { selectUserInfor } from '../../feature/eduIntro/eduIntro';
import { updateStorageOnlyQuotes, updateSystemQuotes } from '../../feature/recommendation/recommendation';
import api from '../../services';
import {
  getCalculatingDetail,
  getCalculatingMockMode,
  getCalculatingTitle,
  getShouldBypassSystemBuilder,
} from '../../AppTheme';
import { StoragePreferenceDtoStoragePreferenceEnum } from '../../services/api';
import { getIsPropertyOwned } from '../../utils';

const CalculatingTitle = getCalculatingTitle();
const CalculatingDetail = getCalculatingDetail();
const Mode = getCalculatingMockMode();

function Calculating() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const userInfor = useSelector(selectUserInfor);
  const userAddress = useSelector(selectUserAddress);
  const minimumWait = 2750;

  useEffect(() => {
    if (hasSubmitted || !userInfor || !userAddress) {
      return;
    }

    setHasSubmitted(true);

    if (Mode === 'SolarAndStorage') {
      console.log('sending to recommendation page');
      history.replace('/recommendation');
      return;
    }

    const { address, appt, city, lat, lng, state, zip } = userAddress;
    const {
      avgMonthlyUtilityBill,
      customerPreferencesDetailData,
      email,
      existingSolarSystemSizeInWat,
      firstName,
      isInterestedInSolar,
      isInterestedInVPP,
      isOwnProperty,
      isSolarAlreadyInstalled,
      lastName,
      phone,
    } = userInfor;

    const doSubmit = async () => {
      try {
        if (getShouldBypassSystemBuilder()) {
          await api.visitApi.visitControllerUpdateAndSync({
            customerPropertyDetail: {
              customerDetail: {
                firstName,
                lastName,
                email,
                phone,
              },
              addressDetail: {
                streetAddress: address,
                aptOrSuite: appt || undefined,
                city: city || '',
                state: state || '',
                zip: zip?.toString() || '',
                lat,
                long: lng,
              },
              isPropertyOwned: getIsPropertyOwned(isOwnProperty),
              utilityDetail: {
                averageMonthlyUtilityBill: avgMonthlyUtilityBill || 0,
              },
              isSolarAlreadyInstalled: !!isSolarAlreadyInstalled,
              existingSolarSystemSizeInWatt: existingSolarSystemSizeInWat,
              isInterestedInSolar: !!isInterestedInSolar,
              isInterestedInVPP: !!isInterestedInVPP,
            },
            customerPreferencesDetails: {
              motivatorPreference: customerPreferencesDetailData.motivatorPreference,
              storagePreference: customerPreferencesDetailData.storagePreference,
              appliances:
                customerPreferencesDetailData.storagePreference.storagePreference ===
                StoragePreferenceDtoStoragePreferenceEnum.EssentialBackup
                  ? customerPreferencesDetailData.appliances
                  : [],
            },
          });
          history.replace('/thank-you');
          return;
        }

        const result = await api.visitApi.visitControllerUpdatePreferences({
          customerPropertyDetail: {
            customerDetail: {
              firstName,
              lastName,
              email,
              phone,
            },
            addressDetail: {
              streetAddress: address,
              aptOrSuite: appt || undefined,
              city: city || '',
              state: state || '',
              zip: zip?.toString() || '',
              lat,
              long: lng,
            },
            isPropertyOwned: getIsPropertyOwned(isOwnProperty),
            utilityDetail: {
              averageMonthlyUtilityBill: avgMonthlyUtilityBill || 0,
            },
            isSolarAlreadyInstalled: !!isSolarAlreadyInstalled,
            existingSolarSystemSizeInWatt: existingSolarSystemSizeInWat,
            isInterestedInSolar: !!isInterestedInSolar,
            isInterestedInVPP: !!isInterestedInVPP,
          },
          customerPreferencesDetails: {
            motivatorPreference: customerPreferencesDetailData.motivatorPreference,
            storagePreference: customerPreferencesDetailData.storagePreference,
            appliances: customerPreferencesDetailData.appliances,
          },
        });

        if (isSolarAlreadyInstalled) {
          const [quotesResult] = await Promise.all([
            api.visitApi.visitControllerGetStorageQuote({ id: result.data.id }),
            new Promise<void>((resolve) => setTimeout(() => resolve(), minimumWait)),
          ]);

          if (quotesResult.data?.quotes?.length > 0) {
            dispatch(updateStorageOnlyQuotes(quotesResult.data));
            history.replace('/storage-recommendation');
          } else {
            history.replace('/problem');
          }
        } else {
          const [quotesResult] = await Promise.all([
            api.visitApi.visitControllerGetSystemStorageQuote({ id: result.data.id }),
            new Promise<void>((resolve) => setTimeout(() => resolve(), minimumWait)),
          ]);

          if (quotesResult.data?.quotes?.length > 0) {
            dispatch(updateSystemQuotes(quotesResult.data));
            history.replace('/recommendation');
          } else {
            history.replace('/problem');
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        history.replace('/problem');
      }
    };

    doSubmit();
  }, [userInfor, userAddress]);

  return (
    <main className="--main --center --calculating">
      <div className="grid-container">
        {!!CalculatingTitle && <h1 className="--max5">{CalculatingTitle}</h1>}

        <div className="loader-bar">
          <div className="__progress" />
        </div>

        {!!CalculatingDetail && <p>{CalculatingDetail}</p>}
      </div>
    </main>
  );
}
export default Calculating;
