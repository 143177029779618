import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetStorageOnlyQuoteResDto, GetSystemStorageQuoteResDto } from '../../services/api';

interface RecommendationState {
  systemQuotes: GetSystemStorageQuoteResDto | undefined;
  storageOnlyQuotes: GetStorageOnlyQuoteResDto | undefined;
}

const initialState: RecommendationState = {
  systemQuotes: undefined,
  storageOnlyQuotes: undefined,
};

const recommendationSlice = createSlice({
  name: 'recommendation',
  initialState,
  reducers: {
    updateSystemQuotes: {
      reducer(state, { payload }: PayloadAction<GetSystemStorageQuoteResDto>) {
        state.systemQuotes = payload;
      },
      prepare: (obj: GetSystemStorageQuoteResDto) => {
        return { payload: obj };
      },
    },
    updateStorageOnlyQuotes: {
      reducer(state, { payload }: PayloadAction<GetStorageOnlyQuoteResDto>) {
        state.storageOnlyQuotes = payload;
      },
      prepare: (obj: GetStorageOnlyQuoteResDto) => {
        return { payload: obj };
      },
    },
  },
});

const selectState = (state: { recommendation: RecommendationState }) => state.recommendation;
export const selectSystemQuotes = createSelector([selectState], (state) => state.systemQuotes);
export const selectStorageOnlyQuotes = createSelector([selectState], (state) => state.storageOnlyQuotes);

export const { updateSystemQuotes, updateStorageOnlyQuotes } = recommendationSlice.actions;
export default recommendationSlice.reducer;
