import React from 'react';

function ThankYou() {
  const phoneRaw = process.env.REACT_APP_CONTACT_PHONE_NUMBER || '';
  const phoneParts = /1?[ -]?\(?(\d{3})\)?[ -]?(\d{3})[ -]?(\d{4})/.exec(phoneRaw);
  let phoneUnformatted = '';
  let phoneFormatted = '';

  if (phoneParts && phoneParts.length > 0) {
    phoneUnformatted = `1${phoneParts[1]}${phoneParts[2]}${phoneParts[3]}`;
    phoneFormatted = `(${phoneParts[1]}) ${phoneParts[2]}-${phoneParts[3]}`;
  }

  return (
    <main className="--main --thank-you">
      <div className="grid-container">
        <h1>Thank you!</h1>
        <div className="grid-row --grid-mtop --justify-center">
          <div className="col-xs-12 col-md-8 col-lg-6">
            <h2>What&#39;s Next?</h2>
            <ul className="__thank-you-list">
              <li>An Energy Consultant will review your request and reach out with additional information.</li>
              <li>
                You&#39;ll receive a customized system design that is best suited for your home and your energy needs.
              </li>
              <li>We coordinate your install and inspection and help you get your system turned on!</li>
            </ul>
          </div>
        </div>
        <div className="grid-row --grid-mtop --justify-center">
          <div className="col-xs-12 col-md-8 col-lg-6">
            <p>
              If you have any questions before, please call <a href={`tel:${phoneUnformatted}`}>{phoneFormatted}</a>
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ThankYou;
