import React from 'react';

function Ineligible() {
  return (
    <main className="--main --info">
      <div className="grid-container">
        <h1>Thank you for your interest</h1>
        <p className="--center">
          Unfortunately, our current offer requires you to own the property. We will keep you informed of future
          programs.
        </p>
      </div>
    </main>
  );
}

export default Ineligible;
