import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { selectUserInfor, updateMotivatorOrder } from '../../feature/eduIntro/eduIntro';
import { MotivatorDtoMotivatorDataOrderEnum, UpdatePreferenceReqDto } from '../../services/api';
import { ReactComponent as ReorderIcon } from '../../assets/img/reorder-option.svg';
import SwellSelfPoweredIcon from '../../assets/img/self-powered.png';
import SwellBackupPowerIcon from '../../assets/img/backup-power.png';
import SwellMaximumSavingsIcon from '../../assets/img/maximum-savings.png';
import SwellReducePollutionIcon from '../../assets/img/reduce-pollution.png';
import LGSelfPoweredIcon from '../../assets/img/self-powered-lg.png';
import LGBackupPowerIcon from '../../assets/img/backup-power-lg.png';
import LGMaximumSavingsIcon from '../../assets/img/maximum-savings-lg.png';
import LGReducePollutionIcon from '../../assets/img/reduce-pollution-lg.png';
import api from '../../services';
import { getPrefsDto } from '../../utils';
import { selectUserAddress } from '../../feature/address/address';

const IsLG = process.env.REACT_APP_THEME === 'LG';
const SelfPoweredIcon = IsLG ? LGSelfPoweredIcon : SwellSelfPoweredIcon;
const BackupPowerIcon = IsLG ? LGBackupPowerIcon : SwellBackupPowerIcon;
const MaximumSavingsIcon = IsLG ? LGMaximumSavingsIcon : SwellMaximumSavingsIcon;
const ReducePollutionIcon = IsLG ? LGReducePollutionIcon : SwellReducePollutionIcon;

function Preference() {
  const motivatorsOderArrConst = [
    MotivatorDtoMotivatorDataOrderEnum.SelfPowered,
    MotivatorDtoMotivatorDataOrderEnum.MaximumSavings,
    MotivatorDtoMotivatorDataOrderEnum.BackupPower,
    MotivatorDtoMotivatorDataOrderEnum.ReducedPollution,
  ];

  const motivatorDescriptions: Record<MotivatorDtoMotivatorDataOrderEnum, string> = {
    [MotivatorDtoMotivatorDataOrderEnum.SelfPowered]:
      'Generating and storing enough power so I am not wholly reliant on the grid.',
    [MotivatorDtoMotivatorDataOrderEnum.MaximumSavings]:
      'Using my smart home energy system to reduce or eliminate my electric spend.',
    [MotivatorDtoMotivatorDataOrderEnum.BackupPower]:
      'The peace of mind of backup power if there’s an unexpected outage in my area.',
    [MotivatorDtoMotivatorDataOrderEnum.ReducedPollution]:
      'Doing my part to reduce my community’s dependence on fossil fuels.',
  };

  const motivatorIcons: Record<MotivatorDtoMotivatorDataOrderEnum, string> = {
    [MotivatorDtoMotivatorDataOrderEnum.SelfPowered]: SelfPoweredIcon,
    [MotivatorDtoMotivatorDataOrderEnum.MaximumSavings]: MaximumSavingsIcon,
    [MotivatorDtoMotivatorDataOrderEnum.BackupPower]: BackupPowerIcon,
    [MotivatorDtoMotivatorDataOrderEnum.ReducedPollution]: ReducePollutionIcon,
  };

  const userInfor = useSelector(selectUserInfor);
  const userAddress = useSelector(selectUserAddress);

  const dispatch = useDispatch();
  const history = useHistory();

  const { customerPreferencesDetailData } = userInfor;

  // const motivatorsOderArr= [1,2,0,3].map()

  const [motivatorPreferences, setmotivatorPreferences] = useState([
    ...(customerPreferencesDetailData.motivatorPreference?.motivatorDataOrder || motivatorsOderArrConst),
  ]);
  const motivatorsArrrtest = motivatorPreferences.map((item) => motivatorsOderArrConst.find((v) => v === item));
  const [motivator, setMotivator] = useState(motivatorsArrrtest);

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = [...motivator];
    const motivatorReorder = [...motivatorPreferences];

    const [reorderedItem] = items.splice(result.source.index, 1);
    const [motivatorReorderItem] = motivatorReorder.splice(result.source.index, 1);

    motivatorReorder.splice(result.destination.index, 0, motivatorReorderItem);
    items.splice(result.destination.index, 0, reorderedItem);
    setmotivatorPreferences(motivatorReorder);
    setMotivator(items);
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    dispatch(updateMotivatorOrder(motivatorPreferences));

    const sourceDto = getPrefsDto(userInfor, userAddress);
    const reqDto: UpdatePreferenceReqDto = {
      ...sourceDto,
      customerPreferencesDetails: {
        motivatorPreference: { motivatorDataOrder: motivatorPreferences },
        storagePreference: sourceDto.customerPreferencesDetails?.storagePreference,
        appliances: sourceDto.customerPreferencesDetails?.appliances,
      },
    };

    api.visitApi.visitControllerUpdateAndSync(reqDto);

    history.push('/battery');
  };

  return (
    // <div className="swell-et-wrap">
    <main className="--main">
      <div className="grid-container">
        <h1>Two more things. What are your motivators?</h1>
        <p className="--center">Drag n’ drop these motivators from most to least important.</p>

        <form id="swell-ecom-tool" className="swell-ecom-tool --grid-mtop">
          <fieldset>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="sortable" direction="vertical">
                {(provided) => (
                  <div
                    id="sortable"
                    className="grid-row --motivators-container"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {motivator.map((item, index) => {
                      return (
                        <Draggable key={item} draggableId={item?.toString() || ''} index={index}>
                          {(providedTwo) => (
                            <div
                              ref={providedTwo.innerRef}
                              {...providedTwo.draggableProps}
                              {...providedTwo.dragHandleProps}
                              className="--motivators col-xs-12 col-md-10"
                              data-order={index + 1}
                            >
                              <span className="__card">
                                <div className="__icon">
                                  <ReorderIcon style={{ width: '100%', height: '100%', fill: '#c0c0c0' }} />
                                </div>
                                <div className="__content --align-center">
                                  <div className="__split">
                                    <span className="__title">{item}</span>
                                    <span className="__txt">{motivatorDescriptions[item!]}</span>
                                  </div>
                                  <span className="__img">
                                    <img src={motivatorIcons[item!]} alt={item} />
                                  </span>
                                </div>
                              </span>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </fieldset>

          <div className="menu-form">
            <Link className="btn --back" to="own-solar">
              <span className="icon-back" />
            </Link>
            <input className="submit btn --submit --continue" type="submit" value="Continue" onClick={handleSubmit} />
            <Link className="btn --restart" to="/">
              <span className="icon-restart" />
            </Link>
          </div>
        </form>
      </div>
    </main>
    // </div>
  );
}
export default Preference;
