export type AppTheme = 'Swell' | 'LG' | 'PenFed';

const getAppTheme = (): AppTheme => {
  if (process.env.REACT_APP_THEME === 'LG') return 'LG';
  if (process.env.REACT_APP_THEME === 'PENFED') return 'PenFed';

  return 'Swell';
};

const Theme = getAppTheme();

export { Theme };

export const getGTMTagId = () => {
  switch (Theme) {
    case 'LG':
      return 'GTM-M3Z4G9P';
    case 'PenFed':
      return '';
    case 'Swell':
    default:
      return 'GTM-MSWJF4D';
  }
};

export const getShouldShowPoweredBy = () => Theme === 'LG';

export const getFooterShowPopover = () => {
  switch (Theme) {
    case 'LG':
      return false;
    case 'PenFed':
    case 'Swell':
    default:
      return true;
  }
};

export const getFooterIsClickable = () => {
  switch (Theme) {
    case 'LG':
      return false;
    case 'PenFed':
    case 'Swell':
    default:
      return true;
  }
};

export const getFooterLeft = () => {
  switch (Theme) {
    case 'LG':
      return "* Expert Energy Advisor is an employee of Swell Energy, \n LG's approved contractor.";
    case 'PenFed':
      return 'Pentagon Federal Credit Union';
    case 'Swell':
    default:
      return 'Swell Energy Inc. CSLB Lic. # 1021183';
  }
};

export const getFooterRight = () => {
  switch (Theme) {
    case 'LG':
      return 'CSLB# 1021183';
    case 'PenFed':
      return '©2022. All rights reserved.';
    case 'Swell':
    default:
      return '©2016 - 2022 Swell Energy. All rights reserved';
  }
};

export const getContactInfoHeader = () => {
  switch (Theme) {
    case 'LG':
      return 'Let’s get you on the path to increased energy confidence, efficiency and independence.';
    case 'PenFed':
      return 'Let’s get started!';
    case 'Swell':
    default:
      return 'Let’s get you on your path to energy independence with Swell!';
  }
};

export const getContactInfoCompanyName = () => {
  switch (Theme) {
    case 'LG':
      return 'LG Electronics';
    case 'PenFed':
      return 'Pentagon Federal Credit Union';
    case 'Swell':
    default:
      return 'Swell Energy';
  }
};

export const getContactInfoPrivacyPolicyURL = () => {
  switch (Theme) {
    case 'LG':
      return '#';
    case 'PenFed':
      return '#';
    case 'Swell':
    default:
      return 'https://swellenergy.com/privacy-policy';
  }
};

export const getShouldAlwaysGoToPreferences = () => Theme !== 'Swell';

export const getShouldPromptForWantSolar = () => Theme !== 'Swell';

export const getShouldPromptForVPP = () => Theme !== 'Swell';

export const getShouldBypassSystemBuilder = () => Theme === 'LG' || Theme === 'Swell';

export const getCalculatingTitle = () => {
  switch (Theme) {
    case 'LG':
    case 'PenFed':
      return '';
    case 'Swell':
    default:
      return 'Hold tight! We’re calculating your optimal Swell Energy system...';
  }
};

export const getCalculatingDetail = () => {
  switch (Theme) {
    case 'LG':
    case 'PenFed':
      return '';
    case 'Swell':
    default:
      return (
        'A Swell Home Energy system brings together Solar, Storage and the Smart Grid ' +
        'for energy independence, security, savings and a brighter future. Our team is ' +
        'always ready to help - from configuring the perfect system to ensuring your ' +
        'install goes smoothly.'
      );
  }
};

export const getCalculatingMockMode = () => {
  switch (Theme) {
    case 'PenFed':
      return 'SolarAndStorage';
    default:
      return 'None';
  }
};

export const getProblemUTMContent = () => {
  switch (Theme) {
    case 'LG':
      return 'LGDSF';
    default:
      return 'ECom_Problem';
  }
};

export const getFooterBaseClassName = () => {
  switch (Theme) {
    case 'LG':
      return 'footer-simple';
    case 'PenFed':
      return 'footer';
    case 'Swell':
    default:
      return 'footer';
  }
};

export const getHeaderClassName = () => {
  switch (Theme) {
    case 'LG':
      return '--simple';
    case 'PenFed':
      return '';
    case 'Swell':
    default:
      return '';
  }
};
