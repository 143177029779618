import React from 'react';

interface Props {
  checked: boolean;
  title: string;
  onChange: (val: boolean) => void;
}

export default function ApplianceCheck({ checked, onChange, title }: Props) {
  return (
    <label>
      <input type="checkbox" checked={checked} onChange={(e) => onChange(e.target.checked)} />
      <span>{title}</span>
    </label>
  );
}
