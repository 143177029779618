import path from 'path';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getHeaderClassName, getShouldShowPoweredBy } from '../AppTheme';
import { getHeaderLogo, getHeaderLogoUrl, withoutHeaderPages } from '../AppThemeComponents';
import SwellLogo from '../assets/img/logo-swell-gray.svg';

function Header() {
  const [progress, setProgress] = useState(0);
  const headerClass = getHeaderClassName();
  const progressLinear: Array<string | string[]> = [
    '/',
    '/contact-info',
    '/address',
    '/service-area-success',
    '/own-solar',
    '/preferences',
    '/battery',
    '/vpp',
    '/calculating',
    ['/recommendation', '/storage-recommendation'],
    '/thank-you',
  ];
  const progressAbend = ['/problem', '/ineligible'];

  const { pathname } = useLocation();

  const link = getHeaderLogoUrl();

  useEffect(() => {
    if (progressAbend.includes(pathname)) {
      setProgress(100);
    }

    const stepNumber = progressLinear.findIndex(
      (p: string | string[]) =>
        (typeof p === 'string' && p === pathname) || (typeof p === 'object' && p.includes(pathname))
    );

    if (stepNumber > -1) {
      // Subtract one from progressLinear because we are ignoring the first page
      // and the last page should show a completely filled bar
      const completion = (stepNumber * 100) / (progressLinear.length - 1);
      setProgress(completion);
    }
  }, [pathname]);

  if (withoutHeaderPages.includes(pathname)) {
    return null;
  }

  return (
    <nav className={`menu-top ${headerClass}`}>
      <div className="menu-top__container ">
        <div>
          {link ? (
            <a id="header_logo_link" target="_blank" rel="noreferrer" href={link}>
              <img alt="logo" className="menu-top__logo" src={getHeaderLogo()} />
            </a>
          ) : (
            <img alt="logo" className="menu-top__logo" src={getHeaderLogo()} />
          )}
        </div>
        {getShouldShowPoweredBy() && (
          <div className="menu-top__powered-by">
            <p>powered by</p>
            <a id="header_powered_by_link" target="_blank" rel="noreferrer" href="https://swellenergy.com/">
              <img alt="Swell" src={SwellLogo} />
            </a>
          </div>
        )}
      </div>
      <div className={`progress-bar ${headerClass}`}>
        <div className="__progress" style={{ width: `${progress}%` }} />
      </div>
    </nav>
  );
}

export default Header;
