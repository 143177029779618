import { Theme } from './AppTheme';
import { IUserInfor, UserAddress } from './reducer/typing';
import { AddVisitDto, CustomerPropertyDetailIsPropertyOwnedEnum, UpdatePreferenceReqDto } from './services/api';

export const getIsPropertyOwned = (isOwnProperty: number | null | undefined) => {
  switch (isOwnProperty) {
    case 1:
      return CustomerPropertyDetailIsPropertyOwnedEnum.Yes;
    case 0:
      return CustomerPropertyDetailIsPropertyOwnedEnum.No;
    default:
      return CustomerPropertyDetailIsPropertyOwnedEnum.Unknown;
  }
};

export const getIsPropertyOwnedInverse = (
  isOwnProperty: CustomerPropertyDetailIsPropertyOwnedEnum | null | undefined
) => {
  switch (isOwnProperty) {
    case CustomerPropertyDetailIsPropertyOwnedEnum.Yes:
      return 1;
    case CustomerPropertyDetailIsPropertyOwnedEnum.No:
      return 0;
    default:
      return null;
  }
};

export function getVisitDto(
  pageName: string,
  userInfo: IUserInfor,
  userAddress: UserAddress,
  isSolarAlreadyInstalled = false,
  existingSolarSystemSizeInWat = 0,
  isInterestedInSolar = false,
  isInterestedInVPP = false,
  utmCampaign: string | null = null,
  campaignUserId: string | null = null
) {
  const { address, appt, city, lat, lng, state, zip } = userAddress;
  const { avgMonthlyUtilityBill, email, firstName, isOwnProperty, lastName, phone } = userInfo;

  const result: AddVisitDto = {
    customerPropertyDetail: {
      customerDetail: {
        firstName,
        lastName,
        email,
        phone,
      },
      addressDetail: {
        streetAddress: address,
        aptOrSuite: appt || '',
        city: city || '',
        state: state || '',
        zip: zip?.toString() || '',
        lat,
        long: lng,
      },
      isPropertyOwned: getIsPropertyOwned(isOwnProperty),
      utilityDetail: {
        averageMonthlyUtilityBill: avgMonthlyUtilityBill || 0,
      },
      isSolarAlreadyInstalled,
      isInterestedInSolar,
      existingSolarSystemSizeInWatt: existingSolarSystemSizeInWat,
      isInterestedInVPP,
    },
    lastPageSeen: pageName,
    storefront: Theme,
    utmCampaign,
    campaignUserId,
  };

  return result;
}

export function getPrefsDto(userInfo: IUserInfor, userAddress: UserAddress) {
  const { address, appt, city, lat, lng, state, zip } = userAddress;
  const {
    avgMonthlyUtilityBill,
    customerPreferencesDetailData,
    email,
    existingSolarSystemSizeInWat,
    firstName,
    isInterestedInSolar,
    isInterestedInVPP,
    isOwnProperty,
    isSolarAlreadyInstalled,
    lastName,
    phone,
  } = userInfo;

  const result: UpdatePreferenceReqDto = {
    customerPropertyDetail: {
      customerDetail: {
        firstName,
        lastName,
        email,
        phone,
      },
      addressDetail: {
        streetAddress: address,
        aptOrSuite: appt || '',
        city: city || '',
        state: state || '',
        zip: zip?.toString() || '',
        lat,
        long: lng,
      },
      isPropertyOwned: getIsPropertyOwned(isOwnProperty),
      utilityDetail: {
        averageMonthlyUtilityBill: avgMonthlyUtilityBill || 0,
      },
      isSolarAlreadyInstalled: !!isSolarAlreadyInstalled,
      isInterestedInSolar: !!isInterestedInSolar,
      existingSolarSystemSizeInWatt: existingSolarSystemSizeInWat,
      isInterestedInVPP: !!isInterestedInVPP,
    },
    customerPreferencesDetails: customerPreferencesDetailData,
  };

  return result;
}
