/* eslint-disable react/no-unescaped-entities */
import { BadgeOutlined, HomeOutlined, MailOutlined, PhoneOutlined } from '@mui/icons-material';
import { CircularProgress, Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
import { useFormik } from 'formik';
import GoogleMapReact, { Coords } from 'google-map-react';
import React, { useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as Sentry from '@sentry/react';
import { selectUserAddress } from '../../feature/address/address';
import useIsMobile from '../../hooks/useIsMobile';
import { UserAddress } from '../../reducer/typing';
import api from '../../services';
import {
  CustomerPropertyDetailIsPropertyOwnedEnum,
  StoragePreferenceDtoStoragePreferenceEnum,
} from '../../services/api';
import { getIsPropertyOwnedInverse, getVisitDto } from '../../utils';
import { DEFAULT_MAP_CONFIG } from '../google_map_config';
import { dedangle } from './dedangle';

const options = [
  { value: CustomerPropertyDetailIsPropertyOwnedEnum.Yes, label: 'Yes' },
  { value: CustomerPropertyDetailIsPropertyOwnedEnum.No, label: 'No' },
];

const hintLastText =
  'and consent to Swell Energy, LG’s approved contractor, reaching out to me on behalf of LG, including by email, calls, and text messages to any telephone number that I provide.';

const subTopicTwo = 'Get in touch with an Expert Energy Advisor* today!';
const subTopicOne =
  'Make power outages a thing of the past with the LG Home 8 Energy Storage System. Enjoy the benefits of having complete control over your power with this home energy system.';

function SimpleForm() {
  const [mapsInstance, setMapsInstance] = useState<any | null>(null);

  const inputEl = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const userGoogleAddress = useSelector(selectUserAddress);

  const isMobile = useIsMobile();
  const history = useHistory();

  const addressBox = inputEl?.current;
  const mapCenter: Coords = {
    lat: userGoogleAddress?.lat || DEFAULT_MAP_CONFIG.center.lat,
    lng: userGoogleAddress?.lng || DEFAULT_MAP_CONFIG.center.lng,
  };

  const validate = (values: any) => {
    const errors: any = {};

    if (!values?.firstName?.trim().split(' ')?.[1]) {
      errors.firstName = 'Please enter your last name';
    }
    if (!values?.firstName.trim()) {
      errors.firstName = 'This field is required.';
    }
    if (values?.phone?.replace(/[^\d]/g, '').length < 10) {
      errors.phone = 'Please enter a valid phone number';
    }
    if (values?.phone === '') {
      errors.phone = 'This field is required.';
    }
    if (values?.city === '' || values?.state === '' || values?.zip === 0 || values?.lat === 0 || values?.long === 0) {
      errors.fullAddress = 'This field is required.';
    }
    if (values?.email === '') {
      errors.email = 'This field is required.';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values?.email)) {
      errors.email = 'Please enter a valid email';
    }
    if (!values?.ownProperty) {
      errors.ownProperty = 'This field is required';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      phone: '',
      firstName: '',
      streetAddress: '',
      aptOrSuite: undefined,
      city: '',
      state: '',
      zip: 0,
      lat: 0,
      long: 0,
      fullAddress: '',
      ownProperty: null,
    },
    validateOnMount: true,
    validate,
    onSubmit: async (values) => {
      const params = new URLSearchParams(window.location.search);
      const utmCampaign = params.get('utm_campaign') ?? null;
      const campaignUserId = params.get('LGE_USER_ID') ?? null;

      setIsLoading(true);
      const { city, email, firstName, lat, long, ownProperty, phone, state, streetAddress, zip } = values;

      const visitDto = getVisitDto(
        'simple-lead-form',
        {
          firstName: firstName.trim().split(' ')?.[0],
          lastName: firstName.trim().slice(firstName.trim().indexOf(' ') + 1),
          email: email.trim(),
          phone,
          contactAgree: true,
          isSolarAlreadyInstalled: undefined,
          existingSolarSystemSizeInWat: 0,
          isInterestedInSolar: undefined,
          isInterestedInVPP: undefined,
          isOwnProperty: getIsPropertyOwnedInverse(ownProperty),
          customerPreferencesDetailData: {
            motivatorPreference: { motivatorDataOrder: [] },
            storagePreference: {
              storagePreference: StoragePreferenceDtoStoragePreferenceEnum.ImNotSure,
            },
            appliances: [],
          },
        },
        {
          address: streetAddress,
          city,
          state,
          zip,
          lat,
          lng: long,
        },
        false,
        0,
        false,
        false,
        utmCampaign,
        campaignUserId
      );

      try {
        await api.visitApi.visitControllerAddVisit(visitDto);
        if (ownProperty === CustomerPropertyDetailIsPropertyOwnedEnum.Yes) history.push('/thank-you');
        else history.push('/thank-you-alt');
      } catch (error) {
        Sentry.captureException(error);
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    if (!addressBox || !mapsInstance) {
      return;
    }

    const searchBox = new mapsInstance.maps.places.Autocomplete(addressBox, {
      types: ['address'],
      fields: ['address_components', 'geometry'],
    });

    searchBox.addListener('place_changed', () => {
      const place = searchBox.getPlace();

      if (!place) return;

      const findComponent = (name: string) => place.address_components?.find((c: any) => c.types[0] === name);
      const payload: UserAddress = {
        address: `${findComponent('street_number')?.long_name} ${findComponent('route')?.short_name}`,
        city: findComponent('locality')?.long_name,
        state: findComponent('administrative_area_level_1')?.short_name,
        zip: findComponent('postal_code')?.long_name,
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng(),
        country: findComponent('country')?.short_name,
      };

      if (
        payload.address &&
        !payload.address.startsWith('undefined') &&
        payload.city &&
        payload.state &&
        payload.zip &&
        payload.lat &&
        payload.lng
      ) {
        const { address, city, country, lat, lng, state, zip } = payload;
        addressBox.value = payload.address;
        formik.setFieldValue('city', city);
        formik.setFieldValue('state', state);
        formik.setFieldValue('zip', zip);
        formik.setFieldValue('lat', lat);
        formik.setFieldValue('long', lng);
        formik.setFieldValue('streetAddress', address);
        formik.setFieldValue('fullAddress', `${address} ${city} ${state} ${country}`);
      } else {
        addressBox.value = '';
      }
    });

    // eslint-disable-next-line consistent-return
    return () => mapsInstance.maps.event.clearInstanceListeners(searchBox);
  }, [addressBox, mapsInstance]);

  const buttonDisabled = !formik.isValid;

  return (
    <main className="--main --simple --center">
      <section>
        <div className="map">
          <GoogleMapReact
            bootstrapURLKeys={{ key: DEFAULT_MAP_CONFIG.key, libraries: DEFAULT_MAP_CONFIG.libraries }}
            center={mapCenter}
            zoom={DEFAULT_MAP_CONFIG.zoom}
            options={{
              mapTypeId: DEFAULT_MAP_CONFIG.mapTypeId,
              tilt: DEFAULT_MAP_CONFIG.tilt,
              maxZoom: DEFAULT_MAP_CONFIG.maxZoom,
              scaleControl: true,
              rotateControl: false,
              fullscreenControl: false,
              scrollwheel: false,
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={(googleInstance: any) => {
              setMapsInstance(googleInstance);
            }}
            style={{ height: '0px', width: '0px' }}
          />
        </div>
        <div className="container">
          <div className="wrapper --right">
            <h1 className="bigTitle">Your Power Is Always On</h1>
            <p className="subtitle">{isMobile ? dedangle(subTopicOne) : subTopicOne}</p>
            <div className="icons-wrapper">
              <span className="icons">
                <img src="/lamp.png" alt="lamp" />
                Avoid Power Disruptions
              </span>
              <span className="icons">
                <img src="/dollar.png" alt="dollar" />
                Increase Energy Savings
              </span>
              <span className="icons">
                <img src="/leaf.png" alt="leaf" />
                Become Energy Efficient
              </span>
            </div>
            <p className="subtitle">{isMobile ? dedangle(subTopicTwo) : subTopicTwo}</p>
          </div>
          <div className="wrapper">
            <form
              id="swell-ecom-tool"
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit(e);
              }}
              noValidate
            >
              <fieldset>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      InputProps={{
                        classes: { root: 'textfiled' },
                        endAdornment: (
                          <InputAdornment position="end">
                            <BadgeOutlined />
                          </InputAdornment>
                        ),
                      }}
                      FormHelperTextProps={{ className: 'errorhint' }}
                      InputLabelProps={{ className: 'textfiledlabel', htmlFor: 'name' }}
                      fullWidth
                      id="name"
                      label="First & Last Name"
                      variant="outlined"
                      {...formik.getFieldProps('firstName')}
                      size="small"
                      helperText={((formik.touched.firstName && formik.errors.firstName) || '') as string}
                      error={(formik.touched.firstName && formik.errors.firstName) as boolean}
                      autoComplete="name"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputMask
                      mask="(999) 999-9999"
                      alwaysShowMask={false}
                      disabled={false}
                      // maskChar=" "
                      {...formik.getFieldProps('phone')}
                    >
                      {() => (
                        <TextField
                          InputLabelProps={{ className: 'textfiledlabel' }}
                          FormHelperTextProps={{ className: 'errorhint' }}
                          InputProps={{
                            classes: { root: 'textfiled' },
                            endAdornment: (
                              <InputAdornment position="end">
                                <PhoneOutlined />
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          id="phone"
                          label="Phone"
                          variant="outlined"
                          size="small"
                          type="tel"
                          helperText={((formik.touched.phone && formik.errors.phone) || '') as string}
                          error={(formik.touched.phone && formik.errors.phone) as boolean}
                          autoComplete="tel"
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      InputLabelProps={{ className: 'textfiledlabel' }}
                      FormHelperTextProps={{ className: 'errorhint' }}
                      InputProps={{
                        classes: { root: 'textfiled' },
                        endAdornment: (
                          <InputAdornment position="end">
                            <MailOutlined />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      id="email"
                      label="Email"
                      variant="outlined"
                      {...formik.getFieldProps('email')}
                      size="small"
                      type="email"
                      helperText={((formik.touched.email && formik.errors.email) || '') as string}
                      error={(formik.touched.email && formik.errors.email) as boolean}
                      autoComplete="email"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      InputLabelProps={{ className: 'textfiledlabel' }}
                      FormHelperTextProps={{ className: 'errorhint' }}
                      InputProps={{
                        classes: { root: 'textfiled' },
                      }}
                      SelectProps={{ className: 'select' }}
                      fullWidth
                      id="select"
                      size="small"
                      select
                      label=" Property Owner?"
                      {...formik.getFieldProps('ownProperty')}
                      helperText={((formik.touched.ownProperty && formik.errors.ownProperty) || '') as string}
                      error={(formik.touched.ownProperty && formik.errors.ownProperty) as boolean}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{ className: 'textfiledlabel' }}
                      FormHelperTextProps={{ className: 'errorhint' }}
                      inputRef={inputEl}
                      InputProps={{
                        classes: { root: 'textfiled' },

                        endAdornment: (
                          <InputAdornment position="end">
                            <HomeOutlined />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      placeholder=""
                      label=" Street Address"
                      variant="outlined"
                      id="address"
                      {...formik.getFieldProps('fullAddress')}
                      size="small"
                      helperText={((formik.touched.fullAddress && formik.errors.fullAddress) || '') as string}
                      error={(formik.touched.fullAddress && formik.errors.fullAddress) as boolean}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <button
                      disabled={buttonDisabled}
                      id="form_submit_button"
                      className=" btn "
                      type={isLoading ? 'button' : 'submit'}
                      onMouseEnter={() => formik.validateForm(formik.values)}
                    >
                      {isLoading ? <CircularProgress className="loading" /> : 'Get Started'}
                    </button>
                  </Grid>

                  <Grid item xs={12}>
                    <p className="agreement">
                      By submitting this form, I agree to the{' '}
                      <a id="form_terms" target="_blank" rel="noreferrer" href="http://attn.tv/lg/terms.html">
                        Terms
                      </a>{' '}
                      and{' '}
                      <a
                        id="form_privacy_policy_lg"
                        target="_blank"
                        rel="noreferrer"
                        href="https://privacy.us.lg.com/policies"
                      >
                        Privacy Policy
                      </a>{' '}
                      and consent to be contacted by or on behalf of LG Electronics U.S.A. Inc. (LG), including by
                      email, calls, and text messages to any telephone number that I provide, about my interest in LG
                      products and services. I understand my consent is not required to purchase. By clicking 'Get
                      Started', I also agree to the{' '}
                      <a
                        id="form_privacy_policy_swell"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.swellenergy.com/privacy-policy"
                      >
                        Privacy Policy
                      </a>{' '}
                      {dedangle(hintLastText)}
                    </p>
                  </Grid>
                </Grid>
              </fieldset>
            </form>
          </div>
        </div>
      </section>
    </main>
  );
}

export default SimpleForm;
