/* tslint:disable */
/* eslint-disable */
/**
 * E Commerce
 * E Commerce API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddVisitDto
 */
export interface AddVisitDto {
    /**
     * 
     * @type {CustomerPropertyDetail}
     * @memberof AddVisitDto
     */
    customerPropertyDetail: CustomerPropertyDetail;
    /**
     * 
     * @type {string}
     * @memberof AddVisitDto
     */
    lastPageSeen: string;
    /**
     * 
     * @type {string}
     * @memberof AddVisitDto
     */
    storefront: string;
    /**
     * 
     * @type {string}
     * @memberof AddVisitDto
     */
    utmCampaign: string;
    /**
     * 
     * @type {string}
     * @memberof AddVisitDto
     */
    campaignUserId: string;
}
/**
 * 
 * @export
 * @interface AddressDetail
 */
export interface AddressDetail {
    /**
     * 
     * @type {string}
     * @memberof AddressDetail
     */
    streetAddress: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetail
     */
    aptOrSuite?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetail
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetail
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetail
     */
    zip: string;
    /**
     * 
     * @type {number}
     * @memberof AddressDetail
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof AddressDetail
     */
    long: number;
}
/**
 * 
 * @export
 * @interface AddressDetailRes
 */
export interface AddressDetailRes {
    /**
     * 
     * @type {string}
     * @memberof AddressDetailRes
     */
    streetAddress: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailRes
     */
    aptOrSuite: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailRes
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailRes
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailRes
     */
    zip: string;
    /**
     * 
     * @type {number}
     * @memberof AddressDetailRes
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof AddressDetailRes
     */
    long: number;
}
/**
 * 
 * @export
 * @interface AuthKeyResDto
 */
export interface AuthKeyResDto {
    /**
     * 
     * @type {string}
     * @memberof AuthKeyResDto
     */
    status: AuthKeyResDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AuthKeyResDto
     */
    authKey: string;
    /**
     * 
     * @type {string}
     * @memberof AuthKeyResDto
     */
    visitId: string;
    /**
     * 
     * @type {string}
     * @memberof AuthKeyResDto
     */
    visitKey: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AuthKeyResDtoStatusEnum {
    InvalidOrder = 'INVALID_ORDER',
    PaymentInProgress = 'PAYMENT_IN_PROGRESS',
    Error = 'ERROR',
    Success = 'SUCCESS'
}

/**
 * 
 * @export
 * @interface CostDetailDataDtoImpl
 */
export interface CostDetailDataDtoImpl {
    /**
     * 
     * @type {string}
     * @memberof CostDetailDataDtoImpl
     */
    energyServiceType: CostDetailDataDtoImplEnergyServiceTypeEnum;
    /**
     * 
     * @type {QuoteDetailDataDtoImpl}
     * @memberof CostDetailDataDtoImpl
     */
    quoteDetail: QuoteDetailDataDtoImpl;
}

/**
    * @export
    * @enum {string}
    */
export enum CostDetailDataDtoImplEnergyServiceTypeEnum {
    SolarOnly = 'swell_esa_solar_only',
    EssentialBackup = 'swell_esa_essential_backup',
    WholeHome = 'swell_esa_whole_home',
    CompleteBackup = 'swell_esa_complete_backup'
}

/**
 * 
 * @export
 * @interface CreateOrderRequestDto
 */
export interface CreateOrderRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequestDto
     */
    selectedPaymentType: CreateOrderRequestDtoSelectedPaymentTypeEnum;
    /**
     * 
     * @type {OrderCustomerPropertyDetail}
     * @memberof CreateOrderRequestDto
     */
    customerPropertyDetail: OrderCustomerPropertyDetail;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderRequestDto
     */
    amount: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateOrderRequestDtoSelectedPaymentTypeEnum {
    UnknownPleaseAudit = 'UNKNOWN_PLEASE_AUDIT',
    Loan = 'loan',
    Cash = 'cash',
    LeaseSolarOnly = 'lease_solar_only',
    LeaseEssentialBackup = 'lease_essential_backup',
    LeaseWholeHomeBackup = 'lease_whole_home_backup',
    LeaseCompleteBackup = 'lease_complete_backup'
}

/**
 * 
 * @export
 * @interface CreateOrderResDto
 */
export interface CreateOrderResDto {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResDto
     */
    status: CreateOrderResDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResDto
     */
    orderId: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateOrderResDtoStatusEnum {
    Success = 'SUCCESS',
    NoRecord = 'NO_RECORD',
    InvalidToken = 'INVALID_TOKEN'
}

/**
 * 
 * @export
 * @interface CustomerDetail
 */
export interface CustomerDetail {
    /**
     * 
     * @type {string}
     * @memberof CustomerDetail
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetail
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetail
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetail
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface CustomerDetailRes
 */
export interface CustomerDetailRes {
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailRes
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailRes
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailRes
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetailRes
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface CustomerPreferencesDetailsDto
 */
export interface CustomerPreferencesDetailsDto {
    /**
     * 
     * @type {MotivatorDto}
     * @memberof CustomerPreferencesDetailsDto
     */
    motivatorPreference: MotivatorDto;
    /**
     * 
     * @type {StoragePreferenceDto}
     * @memberof CustomerPreferencesDetailsDto
     */
    storagePreference: StoragePreferenceDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerPreferencesDetailsDto
     */
    appliances: Array<string>;
}
/**
 * 
 * @export
 * @interface CustomerPropertyDetail
 */
export interface CustomerPropertyDetail {
    /**
     * 
     * @type {CustomerDetail}
     * @memberof CustomerPropertyDetail
     */
    customerDetail: CustomerDetail;
    /**
     * 
     * @type {AddressDetail}
     * @memberof CustomerPropertyDetail
     */
    addressDetail: AddressDetail;
    /**
     * 
     * @type {string}
     * @memberof CustomerPropertyDetail
     */
    isPropertyOwned: CustomerPropertyDetailIsPropertyOwnedEnum;
    /**
     * 
     * @type {UtilityDetail}
     * @memberof CustomerPropertyDetail
     */
    utilityDetail: UtilityDetail;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerPropertyDetail
     */
    isSolarAlreadyInstalled: boolean;
    /**
     * 
     * @type {number}
     * @memberof CustomerPropertyDetail
     */
    existingSolarSystemSizeInWatt: number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerPropertyDetail
     */
    isInterestedInSolar: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerPropertyDetail
     */
    isInterestedInVPP: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CustomerPropertyDetailIsPropertyOwnedEnum {
    Unknown = 'unknown',
    Yes = 'yes',
    No = 'no'
}

/**
 * 
 * @export
 * @interface CustomerPropertyDetailRes
 */
export interface CustomerPropertyDetailRes {
    /**
     * 
     * @type {string}
     * @memberof CustomerPropertyDetailRes
     */
    visitKey: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPropertyDetailRes
     */
    visitIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPropertyDetailRes
     */
    visitId: string;
    /**
     * 
     * @type {CustomerDetailRes}
     * @memberof CustomerPropertyDetailRes
     */
    customerDetail: CustomerDetailRes;
    /**
     * 
     * @type {AddressDetailRes}
     * @memberof CustomerPropertyDetailRes
     */
    addressDetail: AddressDetailRes;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerPropertyDetailRes
     */
    isPropertyOwned: boolean;
    /**
     * 
     * @type {UtilityDetailRes}
     * @memberof CustomerPropertyDetailRes
     */
    utilityDetail: UtilityDetailRes;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerPropertyDetailRes
     */
    isSolarAlreadyInstalled: boolean;
    /**
     * 
     * @type {number}
     * @memberof CustomerPropertyDetailRes
     */
    existingSolarSystemSizeInWatt: number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerPropertyDetailRes
     */
    isInterestedInSolar: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerPropertyDetailRes
     */
    isInterestedInVPP: boolean;
}
/**
 * 
 * @export
 * @interface GetQuoteDto
 */
export interface GetQuoteDto {
    /**
     * 
     * @type {string}
     * @memberof GetQuoteDto
     */
    id: string;
}
/**
 * 
 * @export
 * @interface GetStorageOnlyQuoteResDto
 */
export interface GetStorageOnlyQuoteResDto {
    /**
     * 
     * @type {Array<StorageQuoteDtoImpl>}
     * @memberof GetStorageOnlyQuoteResDto
     */
    quotes: Array<StorageQuoteDtoImpl>;
}
/**
 * 
 * @export
 * @interface GetSystemStorageQuoteResDto
 */
export interface GetSystemStorageQuoteResDto {
    /**
     * 
     * @type {Array<SolarStorageQuoteDtoImpl>}
     * @memberof GetSystemStorageQuoteResDto
     */
    quotes: Array<SolarStorageQuoteDtoImpl>;
    /**
     * 
     * @type {number}
     * @memberof GetSystemStorageQuoteResDto
     */
    typicalUsageCostPerKWh: number;
}
/**
 * 
 * @export
 * @interface MotivatorDto
 */
export interface MotivatorDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof MotivatorDto
     */
    motivatorDataOrder: Array<MotivatorDtoMotivatorDataOrderEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum MotivatorDtoMotivatorDataOrderEnum {
    SelfPowered = 'Self-powered',
    MaximumSavings = 'Maximum Savings',
    BackupPower = 'Backup Power',
    ReducedPollution = 'Reduced Pollution'
}

/**
 * 
 * @export
 * @interface OrderAddressDetail
 */
export interface OrderAddressDetail {
    /**
     * 
     * @type {string}
     * @memberof OrderAddressDetail
     */
    streetAddress: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressDetail
     */
    aptOrSuite?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressDetail
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAddressDetail
     */
    state: string;
    /**
     * 
     * @type {number}
     * @memberof OrderAddressDetail
     */
    zip: number;
    /**
     * 
     * @type {number}
     * @memberof OrderAddressDetail
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof OrderAddressDetail
     */
    long: number;
}
/**
 * 
 * @export
 * @interface OrderCustomerDetail
 */
export interface OrderCustomerDetail {
    /**
     * 
     * @type {string}
     * @memberof OrderCustomerDetail
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCustomerDetail
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCustomerDetail
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCustomerDetail
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface OrderCustomerPropertyDetail
 */
export interface OrderCustomerPropertyDetail {
    /**
     * 
     * @type {string}
     * @memberof OrderCustomerPropertyDetail
     */
    visitKey: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCustomerPropertyDetail
     */
    visitId: string;
    /**
     * 
     * @type {OrderCustomerDetail}
     * @memberof OrderCustomerPropertyDetail
     */
    customerDetail: OrderCustomerDetail;
    /**
     * 
     * @type {OrderAddressDetail}
     * @memberof OrderCustomerPropertyDetail
     */
    addressDetail: OrderAddressDetail;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCustomerPropertyDetail
     */
    isPropertyOwned: boolean;
    /**
     * 
     * @type {OrderUtilityDetail}
     * @memberof OrderCustomerPropertyDetail
     */
    utilityDetail: OrderUtilityDetail;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCustomerPropertyDetail
     */
    isSolarAlreadyInstalled: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderCustomerPropertyDetail
     */
    existingSolarSystemSizeInWatt: number;
}
/**
 * 
 * @export
 * @interface OrderPvModuleDetailDto
 */
export interface OrderPvModuleDetailDto {
    /**
     * 
     * @type {number}
     * @memberof OrderPvModuleDetailDto
     */
    systemKW: number;
    /**
     * 
     * @type {number}
     * @memberof OrderPvModuleDetailDto
     */
    percentageOfSelfPower: number;
    /**
     * 
     * @type {number}
     * @memberof OrderPvModuleDetailDto
     */
    estimatedTwentyFiveYearsSavings: number;
}
/**
 * 
 * @export
 * @interface OrderStorageSystemDetailDto
 */
export interface OrderStorageSystemDetailDto {
    /**
     * 
     * @type {number}
     * @memberof OrderStorageSystemDetailDto
     */
    storageSystemCount: number;
    /**
     * 
     * @type {number}
     * @memberof OrderStorageSystemDetailDto
     */
    storageSystemKWh: number;
}
/**
 * 
 * @export
 * @interface OrderUtilityDetail
 */
export interface OrderUtilityDetail {
    /**
     * 
     * @type {number}
     * @memberof OrderUtilityDetail
     */
    averageMonthlyUtilityBill: number;
}
/**
 * 
 * @export
 * @interface PaymentDetailDataDtoImpl
 */
export interface PaymentDetailDataDtoImpl {
    /**
     * 
     * @type {number}
     * @memberof PaymentDetailDataDtoImpl
     */
    monthlyPaymentAmount: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentDetailDataDtoImpl
     */
    savingsFiveYear: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentDetailDataDtoImpl
     */
    savingTwentyFiveYear: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentDetailDataDtoImpl
     */
    deposit: number;
}
/**
 * 
 * @export
 * @interface PaymentOptionDataDtoImpl
 */
export interface PaymentOptionDataDtoImpl {
    /**
     * 
     * @type {string}
     * @memberof PaymentOptionDataDtoImpl
     */
    paymentType: PaymentOptionDataDtoImplPaymentTypeEnum;
    /**
     * 
     * @type {PaymentDetailDataDtoImpl}
     * @memberof PaymentOptionDataDtoImpl
     */
    paymentDetail: PaymentDetailDataDtoImpl;
}

/**
    * @export
    * @enum {string}
    */
export enum PaymentOptionDataDtoImplPaymentTypeEnum {
    UnknownPleaseAudit = ' unknown_please_audit',
    Loan = 'loan',
    Cash = 'cash',
    LeaseSolarOnly = 'lease_solar_only',
    LeaseEssentialBackup = 'lease_essential_backup',
    LeaseWholeHomeBackup = 'lease_whole_home_backup',
    LeaseCompleteBackup = 'lease_complete_backup'
}

/**
 * 
 * @export
 * @interface PvModuleDetailDataDtoImpl
 */
export interface PvModuleDetailDataDtoImpl {
    /**
     * 
     * @type {number}
     * @memberof PvModuleDetailDataDtoImpl
     */
    systemKW: number;
    /**
     * 
     * @type {number}
     * @memberof PvModuleDetailDataDtoImpl
     */
    percentageOfSelfPower: number;
    /**
     * 
     * @type {number}
     * @memberof PvModuleDetailDataDtoImpl
     */
    estimatedTwentyFiveYearsSavings: number;
}
/**
 * 
 * @export
 * @interface QuoteDetailDataDtoImpl
 */
export interface QuoteDetailDataDtoImpl {
    /**
     * 
     * @type {number}
     * @memberof QuoteDetailDataDtoImpl
     */
    monthlyCost: number;
    /**
     * 
     * @type {number}
     * @memberof QuoteDetailDataDtoImpl
     */
    pricePerKwh: number;
    /**
     * 
     * @type {number}
     * @memberof QuoteDetailDataDtoImpl
     */
    pricePerKwhWithStorage: number;
    /**
     * 
     * @type {number}
     * @memberof QuoteDetailDataDtoImpl
     */
    estimatedIncrease: number;
    /**
     * 
     * @type {number}
     * @memberof QuoteDetailDataDtoImpl
     */
    estimatedBillInTenYears: number;
    /**
     * 
     * @type {number}
     * @memberof QuoteDetailDataDtoImpl
     */
    cumulativeSavingsOverTwentyFiveYears: number;
}
/**
 * 
 * @export
 * @interface SelectedOption
 */
export interface SelectedOption {
    /**
     * 
     * @type {OrderPvModuleDetailDto}
     * @memberof SelectedOption
     */
    pvModuleDetailData: OrderPvModuleDetailDto;
    /**
     * 
     * @type {OrderStorageSystemDetailDto}
     * @memberof SelectedOption
     */
    storageSystemDetailData: OrderStorageSystemDetailDto;
    /**
     * 
     * @type {number}
     * @memberof SelectedOption
     */
    pricePerKwh: number;
    /**
     * 
     * @type {number}
     * @memberof SelectedOption
     */
    monthlyPayment: number;
}
/**
 * 
 * @export
 * @interface SolarStorageQuoteDtoImpl
 */
export interface SolarStorageQuoteDtoImpl {
    /**
     * 
     * @type {number}
     * @memberof SolarStorageQuoteDtoImpl
     */
    numberOfPanels: number;
    /**
     * 
     * @type {boolean}
     * @memberof SolarStorageQuoteDtoImpl
     */
    isDefault: boolean;
    /**
     * 
     * @type {PvModuleDetailDataDtoImpl}
     * @memberof SolarStorageQuoteDtoImpl
     */
    pvModuleDetailData: PvModuleDetailDataDtoImpl;
    /**
     * 
     * @type {Array<StorageQuoteDtoImpl>}
     * @memberof SolarStorageQuoteDtoImpl
     */
    storageData: Array<StorageQuoteDtoImpl>;
}
/**
 * 
 * @export
 * @interface StoragePreferenceDto
 */
export interface StoragePreferenceDto {
    /**
     * 
     * @type {string}
     * @memberof StoragePreferenceDto
     */
    storagePreference: StoragePreferenceDtoStoragePreferenceEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum StoragePreferenceDtoStoragePreferenceEnum {
    EssentialBackup = 'Essential Backup',
    WholeHomeBackup = 'Whole Home Backup',
    ImNotSure = 'I’m not sure',
    ImNotInterested = 'I’m not interested'
}

/**
 * 
 * @export
 * @interface StorageQuoteDtoImpl
 */
export interface StorageQuoteDtoImpl {
    /**
     * 
     * @type {StorageSystemDetailDataDtoImpl}
     * @memberof StorageQuoteDtoImpl
     */
    storageSystemDetailData: StorageSystemDetailDataDtoImpl;
    /**
     * 
     * @type {Array<CostDetailDataDtoImpl>}
     * @memberof StorageQuoteDtoImpl
     */
    costDetailsData: Array<CostDetailDataDtoImpl>;
    /**
     * 
     * @type {Array<PaymentOptionDataDtoImpl>}
     * @memberof StorageQuoteDtoImpl
     */
    paymentOptionData: Array<PaymentOptionDataDtoImpl>;
}
/**
 * 
 * @export
 * @interface StorageSystemDetailDataDtoImpl
 */
export interface StorageSystemDetailDataDtoImpl {
    /**
     * 
     * @type {number}
     * @memberof StorageSystemDetailDataDtoImpl
     */
    storageSystemCount: number;
    /**
     * 
     * @type {number}
     * @memberof StorageSystemDetailDataDtoImpl
     */
    storageSystemKWh: number;
    /**
     * 
     * @type {number}
     * @memberof StorageSystemDetailDataDtoImpl
     */
    numberOfDaysBackup: number;
    /**
     * 
     * @type {string}
     * @memberof StorageSystemDetailDataDtoImpl
     */
    backupDetailsTest: string;
}
/**
 * 
 * @export
 * @interface UpdateOrderStatusReqDto
 */
export interface UpdateOrderStatusReqDto {
    /**
     * 
     * @type {OrderCustomerPropertyDetail}
     * @memberof UpdateOrderStatusReqDto
     */
    customerAndPropertyDetail: OrderCustomerPropertyDetail;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderStatusReqDto
     */
    authKey?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderStatusReqDto
     */
    amt?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderStatusReqDto
     */
    status: UpdateOrderStatusReqDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderStatusReqDto
     */
    refId?: string;
    /**
     * 
     * @type {SelectedOption}
     * @memberof UpdateOrderStatusReqDto
     */
    selectedOption: SelectedOption;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateOrderStatusReqDtoStatusEnum {
    NotAttempted = 'NOT_ATTEMPTED',
    Initiating = 'INITIATING',
    Success = 'SUCCESS',
    Failure = 'FAILURE'
}

/**
 * 
 * @export
 * @interface UpdateOrderStatusResponseDto
 */
export interface UpdateOrderStatusResponseDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderStatusResponseDto
     */
    status: UpdateOrderStatusResponseDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateOrderStatusResponseDtoStatusEnum {
    Success = 'SUCCESS',
    UnknownOrder = 'UNKNOWN_ORDER',
    PaymentNotInitiated = 'PAYMENT_NOT_INITIATED'
}

/**
 * 
 * @export
 * @interface UpdatePreferenceReqDto
 */
export interface UpdatePreferenceReqDto {
    /**
     * 
     * @type {CustomerPropertyDetail}
     * @memberof UpdatePreferenceReqDto
     */
    customerPropertyDetail: CustomerPropertyDetail;
    /**
     * 
     * @type {CustomerPreferencesDetailsDto}
     * @memberof UpdatePreferenceReqDto
     */
    customerPreferencesDetails: CustomerPreferencesDetailsDto;
}
/**
 * 
 * @export
 * @interface UpdatePreferenceResDto
 */
export interface UpdatePreferenceResDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePreferenceResDto
     */
    id: string;
    /**
     * 
     * @type {CustomerPropertyDetailRes}
     * @memberof UpdatePreferenceResDto
     */
    customerPropertyDetail: CustomerPropertyDetailRes;
}
/**
 * 
 * @export
 * @interface UtilityDetail
 */
export interface UtilityDetail {
    /**
     * 
     * @type {number}
     * @memberof UtilityDetail
     */
    averageMonthlyUtilityBill: number;
}
/**
 * 
 * @export
 * @interface UtilityDetailRes
 */
export interface UtilityDetailRes {
    /**
     * 
     * @type {number}
     * @memberof UtilityDetailRes
     */
    averageMonthlyUtilityBill: number;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerGetHealth: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerGetHealth(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).healthControllerGetHealth(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerGetHealth(options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).healthControllerGetHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerGetHealth(options?: any) {
        return DefaultApiFp(this.configuration).healthControllerGetHealth(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrderRequestDto} createOrderRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerCreateOrder: async (createOrderRequestDto: CreateOrderRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderRequestDto' is not null or undefined
            if (createOrderRequestDto === null || createOrderRequestDto === undefined) {
                throw new RequiredError('createOrderRequestDto','Required parameter createOrderRequestDto was null or undefined when calling ordersControllerCreateOrder.');
            }
            const localVarPath = `/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createOrderRequestDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createOrderRequestDto !== undefined ? createOrderRequestDto : {})
                : (createOrderRequestDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerGetPaymentAuthorizationKey: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling ordersControllerGetPaymentAuthorizationKey.');
            }
            const localVarPath = `/orders/get-payment-auth-key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {UpdateOrderStatusReqDto} updateOrderStatusReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerUpdateOrderStatus: async (orderId: string, updateOrderStatusReqDto: UpdateOrderStatusReqDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling ordersControllerUpdateOrderStatus.');
            }
            // verify required parameter 'updateOrderStatusReqDto' is not null or undefined
            if (updateOrderStatusReqDto === null || updateOrderStatusReqDto === undefined) {
                throw new RequiredError('updateOrderStatusReqDto','Required parameter updateOrderStatusReqDto was null or undefined when calling ordersControllerUpdateOrderStatus.');
            }
            const localVarPath = `/orders/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateOrderStatusReqDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateOrderStatusReqDto !== undefined ? updateOrderStatusReqDto : {})
                : (updateOrderStatusReqDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrderRequestDto} createOrderRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerCreateOrder(createOrderRequestDto: CreateOrderRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrderResDto>> {
            const localVarAxiosArgs = await OrderApiAxiosParamCreator(configuration).ordersControllerCreateOrder(createOrderRequestDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerGetPaymentAuthorizationKey(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthKeyResDto>> {
            const localVarAxiosArgs = await OrderApiAxiosParamCreator(configuration).ordersControllerGetPaymentAuthorizationKey(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {UpdateOrderStatusReqDto} updateOrderStatusReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerUpdateOrderStatus(orderId: string, updateOrderStatusReqDto: UpdateOrderStatusReqDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateOrderStatusResponseDto>> {
            const localVarAxiosArgs = await OrderApiAxiosParamCreator(configuration).ordersControllerUpdateOrderStatus(orderId, updateOrderStatusReqDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateOrderRequestDto} createOrderRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerCreateOrder(createOrderRequestDto: CreateOrderRequestDto, options?: any): AxiosPromise<CreateOrderResDto> {
            return OrderApiFp(configuration).ordersControllerCreateOrder(createOrderRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerGetPaymentAuthorizationKey(id: string, options?: any): AxiosPromise<AuthKeyResDto> {
            return OrderApiFp(configuration).ordersControllerGetPaymentAuthorizationKey(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {UpdateOrderStatusReqDto} updateOrderStatusReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerUpdateOrderStatus(orderId: string, updateOrderStatusReqDto: UpdateOrderStatusReqDto, options?: any): AxiosPromise<UpdateOrderStatusResponseDto> {
            return OrderApiFp(configuration).ordersControllerUpdateOrderStatus(orderId, updateOrderStatusReqDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @param {CreateOrderRequestDto} createOrderRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public ordersControllerCreateOrder(createOrderRequestDto: CreateOrderRequestDto, options?: any) {
        return OrderApiFp(this.configuration).ordersControllerCreateOrder(createOrderRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public ordersControllerGetPaymentAuthorizationKey(id: string, options?: any) {
        return OrderApiFp(this.configuration).ordersControllerGetPaymentAuthorizationKey(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {UpdateOrderStatusReqDto} updateOrderStatusReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public ordersControllerUpdateOrderStatus(orderId: string, updateOrderStatusReqDto: UpdateOrderStatusReqDto, options?: any) {
        return OrderApiFp(this.configuration).ordersControllerUpdateOrderStatus(orderId, updateOrderStatusReqDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VisitApi - axios parameter creator
 * @export
 */
export const VisitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddVisitDto} addVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerAddVisit: async (addVisitDto: AddVisitDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addVisitDto' is not null or undefined
            if (addVisitDto === null || addVisitDto === undefined) {
                throw new RequiredError('addVisitDto','Required parameter addVisitDto was null or undefined when calling visitControllerAddVisit.');
            }
            const localVarPath = `/visits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof addVisitDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(addVisitDto !== undefined ? addVisitDto : {})
                : (addVisitDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetQuoteDto} getQuoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetStorageQuote: async (getQuoteDto: GetQuoteDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getQuoteDto' is not null or undefined
            if (getQuoteDto === null || getQuoteDto === undefined) {
                throw new RequiredError('getQuoteDto','Required parameter getQuoteDto was null or undefined when calling visitControllerGetStorageQuote.');
            }
            const localVarPath = `/visits/storage-quote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getQuoteDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getQuoteDto !== undefined ? getQuoteDto : {})
                : (getQuoteDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetQuoteDto} getQuoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetSystemStorageQuote: async (getQuoteDto: GetQuoteDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getQuoteDto' is not null or undefined
            if (getQuoteDto === null || getQuoteDto === undefined) {
                throw new RequiredError('getQuoteDto','Required parameter getQuoteDto was null or undefined when calling visitControllerGetSystemStorageQuote.');
            }
            const localVarPath = `/visits/system-storage-quote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getQuoteDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getQuoteDto !== undefined ? getQuoteDto : {})
                : (getQuoteDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePreferenceReqDto} updatePreferenceReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerUpdateAndSync: async (updatePreferenceReqDto: UpdatePreferenceReqDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePreferenceReqDto' is not null or undefined
            if (updatePreferenceReqDto === null || updatePreferenceReqDto === undefined) {
                throw new RequiredError('updatePreferenceReqDto','Required parameter updatePreferenceReqDto was null or undefined when calling visitControllerUpdateAndSync.');
            }
            const localVarPath = `/visits/update-sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updatePreferenceReqDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updatePreferenceReqDto !== undefined ? updatePreferenceReqDto : {})
                : (updatePreferenceReqDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePreferenceReqDto} updatePreferenceReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerUpdatePreferences: async (updatePreferenceReqDto: UpdatePreferenceReqDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePreferenceReqDto' is not null or undefined
            if (updatePreferenceReqDto === null || updatePreferenceReqDto === undefined) {
                throw new RequiredError('updatePreferenceReqDto','Required parameter updatePreferenceReqDto was null or undefined when calling visitControllerUpdatePreferences.');
            }
            const localVarPath = `/visits/preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updatePreferenceReqDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updatePreferenceReqDto !== undefined ? updatePreferenceReqDto : {})
                : (updatePreferenceReqDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VisitApi - functional programming interface
 * @export
 */
export const VisitApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddVisitDto} addVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerAddVisit(addVisitDto: AddVisitDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VisitApiAxiosParamCreator(configuration).visitControllerAddVisit(addVisitDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GetQuoteDto} getQuoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerGetStorageQuote(getQuoteDto: GetQuoteDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStorageOnlyQuoteResDto>> {
            const localVarAxiosArgs = await VisitApiAxiosParamCreator(configuration).visitControllerGetStorageQuote(getQuoteDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GetQuoteDto} getQuoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerGetSystemStorageQuote(getQuoteDto: GetQuoteDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSystemStorageQuoteResDto>> {
            const localVarAxiosArgs = await VisitApiAxiosParamCreator(configuration).visitControllerGetSystemStorageQuote(getQuoteDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdatePreferenceReqDto} updatePreferenceReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerUpdateAndSync(updatePreferenceReqDto: UpdatePreferenceReqDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatePreferenceResDto>> {
            const localVarAxiosArgs = await VisitApiAxiosParamCreator(configuration).visitControllerUpdateAndSync(updatePreferenceReqDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdatePreferenceReqDto} updatePreferenceReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerUpdatePreferences(updatePreferenceReqDto: UpdatePreferenceReqDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatePreferenceResDto>> {
            const localVarAxiosArgs = await VisitApiAxiosParamCreator(configuration).visitControllerUpdatePreferences(updatePreferenceReqDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * VisitApi - factory interface
 * @export
 */
export const VisitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {AddVisitDto} addVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerAddVisit(addVisitDto: AddVisitDto, options?: any): AxiosPromise<void> {
            return VisitApiFp(configuration).visitControllerAddVisit(addVisitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetQuoteDto} getQuoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetStorageQuote(getQuoteDto: GetQuoteDto, options?: any): AxiosPromise<GetStorageOnlyQuoteResDto> {
            return VisitApiFp(configuration).visitControllerGetStorageQuote(getQuoteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetQuoteDto} getQuoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetSystemStorageQuote(getQuoteDto: GetQuoteDto, options?: any): AxiosPromise<GetSystemStorageQuoteResDto> {
            return VisitApiFp(configuration).visitControllerGetSystemStorageQuote(getQuoteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePreferenceReqDto} updatePreferenceReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerUpdateAndSync(updatePreferenceReqDto: UpdatePreferenceReqDto, options?: any): AxiosPromise<UpdatePreferenceResDto> {
            return VisitApiFp(configuration).visitControllerUpdateAndSync(updatePreferenceReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePreferenceReqDto} updatePreferenceReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerUpdatePreferences(updatePreferenceReqDto: UpdatePreferenceReqDto, options?: any): AxiosPromise<UpdatePreferenceResDto> {
            return VisitApiFp(configuration).visitControllerUpdatePreferences(updatePreferenceReqDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VisitApi - object-oriented interface
 * @export
 * @class VisitApi
 * @extends {BaseAPI}
 */
export class VisitApi extends BaseAPI {
    /**
     * 
     * @param {AddVisitDto} addVisitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerAddVisit(addVisitDto: AddVisitDto, options?: any) {
        return VisitApiFp(this.configuration).visitControllerAddVisit(addVisitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetQuoteDto} getQuoteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerGetStorageQuote(getQuoteDto: GetQuoteDto, options?: any) {
        return VisitApiFp(this.configuration).visitControllerGetStorageQuote(getQuoteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetQuoteDto} getQuoteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerGetSystemStorageQuote(getQuoteDto: GetQuoteDto, options?: any) {
        return VisitApiFp(this.configuration).visitControllerGetSystemStorageQuote(getQuoteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdatePreferenceReqDto} updatePreferenceReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerUpdateAndSync(updatePreferenceReqDto: UpdatePreferenceReqDto, options?: any) {
        return VisitApiFp(this.configuration).visitControllerUpdateAndSync(updatePreferenceReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdatePreferenceReqDto} updatePreferenceReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerUpdatePreferences(updatePreferenceReqDto: UpdatePreferenceReqDto, options?: any) {
        return VisitApiFp(this.configuration).visitControllerUpdatePreferences(updatePreferenceReqDto, options).then((request) => request(this.axios, this.basePath));
    }
}


