import React from 'react';
import useIsMobile from '../../hooks/useIsMobile';
import { dedangle } from '../SimpleForm/dedangle';

const text =
  'Thank you for submitting your information.  You will be contacted shortly by an Expert Energy Advisor* to discuss your options and find a solution to fit your needs.';

function NewThankYouYes() {
  const isMobile = useIsMobile();

  return (
    <main className="--main --simple --center">
      <section>
        <div className="container --small">
          <div className="wrapper --right">
            <h1 className="bigTitle --small">We Are Excited To Speak To You!</h1>

            <p className="subtitle --center">{isMobile ? text : dedangle(text)}</p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default NewThankYouYes;
