import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import iconSolar from '../../assets/img/icon-solar-panel.svg';
import iconNoBattery from '../../assets/img/icon-no-battery.svg';
import iconBattery from '../../assets/img/icon-tesla-battery.svg';
import iconBattery2X from '../../assets/img/icon-tesla-battery-2x.svg';
import DynamicHome from './DynamicHome';
import api from '../../services';
import {
  AuthKeyResDtoStatusEnum,
  CostDetailDataDtoImplEnergyServiceTypeEnum,
  CreateOrderRequestDtoSelectedPaymentTypeEnum,
  CreateOrderResDtoStatusEnum,
  PaymentOptionDataDtoImplPaymentTypeEnum,
  StorageQuoteDtoImpl,
  UpdateOrderStatusReqDtoStatusEnum,
} from '../../services/api';
import { selectUserInfor } from '../../feature/eduIntro/eduIntro';
import { selectUserAddress } from '../../feature/address/address';

const paymentTypeMap: Record<PaymentOptionDataDtoImplPaymentTypeEnum, CreateOrderRequestDtoSelectedPaymentTypeEnum> = {
  [PaymentOptionDataDtoImplPaymentTypeEnum.UnknownPleaseAudit]:
    CreateOrderRequestDtoSelectedPaymentTypeEnum.UnknownPleaseAudit,
  [PaymentOptionDataDtoImplPaymentTypeEnum.Cash]: CreateOrderRequestDtoSelectedPaymentTypeEnum.Cash,
  [PaymentOptionDataDtoImplPaymentTypeEnum.Loan]: CreateOrderRequestDtoSelectedPaymentTypeEnum.Loan,
  [PaymentOptionDataDtoImplPaymentTypeEnum.LeaseSolarOnly]: CreateOrderRequestDtoSelectedPaymentTypeEnum.LeaseSolarOnly,
  [PaymentOptionDataDtoImplPaymentTypeEnum.LeaseEssentialBackup]:
    CreateOrderRequestDtoSelectedPaymentTypeEnum.LeaseEssentialBackup,
  [PaymentOptionDataDtoImplPaymentTypeEnum.LeaseWholeHomeBackup]:
    CreateOrderRequestDtoSelectedPaymentTypeEnum.LeaseWholeHomeBackup,
  [PaymentOptionDataDtoImplPaymentTypeEnum.LeaseCompleteBackup]:
    CreateOrderRequestDtoSelectedPaymentTypeEnum.LeaseCompleteBackup,
};

const paymentTypeEnergyServiceMap: Record<
  PaymentOptionDataDtoImplPaymentTypeEnum,
  CostDetailDataDtoImplEnergyServiceTypeEnum | null
> = {
  [PaymentOptionDataDtoImplPaymentTypeEnum.UnknownPleaseAudit]: null,
  [PaymentOptionDataDtoImplPaymentTypeEnum.Cash]: null,
  [PaymentOptionDataDtoImplPaymentTypeEnum.Loan]: null,
  [PaymentOptionDataDtoImplPaymentTypeEnum.LeaseSolarOnly]: CostDetailDataDtoImplEnergyServiceTypeEnum.SolarOnly,
  [PaymentOptionDataDtoImplPaymentTypeEnum.LeaseEssentialBackup]:
    CostDetailDataDtoImplEnergyServiceTypeEnum.EssentialBackup,
  [PaymentOptionDataDtoImplPaymentTypeEnum.LeaseWholeHomeBackup]: CostDetailDataDtoImplEnergyServiceTypeEnum.WholeHome,
  [PaymentOptionDataDtoImplPaymentTypeEnum.LeaseCompleteBackup]:
    CostDetailDataDtoImplEnergyServiceTypeEnum.CompleteBackup,
};

function PFRecommendation() {
  const history = useHistory();
  const userInfor = useSelector(selectUserInfor);
  const userAddress = useSelector(selectUserAddress);
  const { address, appt, city, lat, lng, state, zip } = userAddress;
  const {
    email,
    existingSolarSystemSizeInWat,
    firstName,
    isOwnProperty,
    isSolarAlreadyInstalled,
    lastName,
    phone,
  } = userInfor;
  const [batteryCount, setBatteryCount] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [buttonStatus, setButtonStatus] = useState('');
  const [selectedPaymentType, setSelectedPaymentType] = useState(
    PaymentOptionDataDtoImplPaymentTypeEnum.UnknownPleaseAudit
  );

  const noStorageQuote: StorageQuoteDtoImpl = {
    storageSystemDetailData: {
      storageSystemCount: 1,
      storageSystemKWh: 9.6,
      numberOfDaysBackup: 2,
      backupDetailsTest: '',
    },
    costDetailsData: [],
    paymentOptionData: [
      {
        paymentType: PaymentOptionDataDtoImplPaymentTypeEnum.Cash,
        paymentDetail: {
          monthlyPaymentAmount: 12572,
          savingsFiveYear: 0,
          savingTwentyFiveYear: 0,
          deposit: 0,
        },
      },
      {
        paymentType: PaymentOptionDataDtoImplPaymentTypeEnum.Loan,
        paymentDetail: {
          monthlyPaymentAmount: 78.47,
          savingsFiveYear: 0,
          savingTwentyFiveYear: 0,
          deposit: 0,
        },
      },
    ],
  };

  const oneStorageQuote: StorageQuoteDtoImpl = {
    storageSystemDetailData: {
      storageSystemCount: 1,
      storageSystemKWh: 9.6,
      numberOfDaysBackup: 2,
      backupDetailsTest: '',
    },
    costDetailsData: [],
    paymentOptionData: [
      {
        paymentType: PaymentOptionDataDtoImplPaymentTypeEnum.Cash,
        paymentDetail: {
          monthlyPaymentAmount: 24531,
          savingsFiveYear: 0,
          savingTwentyFiveYear: 0,
          deposit: 0,
        },
      },
      {
        paymentType: PaymentOptionDataDtoImplPaymentTypeEnum.Loan,
        paymentDetail: {
          monthlyPaymentAmount: 153.12,
          savingsFiveYear: 0,
          savingTwentyFiveYear: 0,
          deposit: 0,
        },
      },
    ],
  };

  const twoStorageQuote: StorageQuoteDtoImpl = {
    storageSystemDetailData: {
      storageSystemCount: 2,
      storageSystemKWh: 19.2,
      numberOfDaysBackup: 5,
      backupDetailsTest: '',
    },
    costDetailsData: [],
    paymentOptionData: [
      {
        paymentType: PaymentOptionDataDtoImplPaymentTypeEnum.Cash,
        paymentDetail: {
          monthlyPaymentAmount: 33929,
          savingsFiveYear: 0,
          savingTwentyFiveYear: 0,
          deposit: 0,
        },
      },
      {
        paymentType: PaymentOptionDataDtoImplPaymentTypeEnum.Loan,
        paymentDetail: {
          monthlyPaymentAmount: 211.78,
          savingsFiveYear: 0,
          savingTwentyFiveYear: 0,
          deposit: 0,
        },
      },
    ],
  };

  const usdFormatterOpts = { style: 'currency', currency: 'USD', minimumFractionDigits: 0 };
  const usdFormatter = new Intl.NumberFormat(undefined, usdFormatterOpts);
  const phoneRaw = process.env.REACT_APP_CONTACT_PHONE_NUMBER || '';
  const phoneParts = /1?[ -]?\(?(\d{3})\)?[ -]?(\d{3})[ -]?(\d{4})/.exec(phoneRaw);
  let phoneUnformatted = '';
  let phoneFormatted = '';

  if (phoneParts && phoneParts.length > 0) {
    phoneUnformatted = `1${phoneParts[1]}${phoneParts[2]}${phoneParts[3]}`;
    phoneFormatted = `(${phoneParts[1]}) ${phoneParts[2]}-${phoneParts[3]}`;
  }

  const storageAccessor = [noStorageQuote, oneStorageQuote, twoStorageQuote];
  const selectedStorage = storageAccessor[batteryCount];

  const loanPayment = selectedStorage.paymentOptionData.find(
    (p) => p.paymentType === PaymentOptionDataDtoImplPaymentTypeEnum.Loan && p.paymentDetail
  );
  const cashPayment = selectedStorage.paymentOptionData.find(
    (p) => p.paymentType === PaymentOptionDataDtoImplPaymentTypeEnum.Cash && p.paymentDetail
  );

  const doReserveSystem = async () => {
    if (buttonStatus !== '--continue') return;

    setIsSubmitting(true);

    const customerPropertyDetail = {
      visitId: '',
      visitKey: '',
      customerDetail: {
        firstName,
        lastName,
        email,
        phone,
      },
      addressDetail: {
        streetAddress: address,
        aptOrSuite: appt || undefined,
        city: city || '',
        state: state || '',
        zip: zip || 0,
        lat,
        long: lng,
      },
      isPropertyOwned: !!isOwnProperty && isOwnProperty > 0,
      utilityDetail: {
        averageMonthlyUtilityBill: 0,
      },
      isSolarAlreadyInstalled,
      existingSolarSystemSizeInWatt: existingSolarSystemSizeInWat,
    };

    const order = await api.orderApi.ordersControllerCreateOrder({
      selectedPaymentType: paymentTypeMap[selectedPaymentType],
      amount: 0,
      customerPropertyDetail: {
        ...customerPropertyDetail,
        isSolarAlreadyInstalled: !!customerPropertyDetail.isSolarAlreadyInstalled,
      },
    });

    if (order.status > 299 || order.data?.status !== CreateOrderResDtoStatusEnum.Success) {
      history.push('/problem');
      return;
    }

    const authKeyResp = await api.orderApi.ordersControllerGetPaymentAuthorizationKey(order.data.orderId);

    if (authKeyResp.status > 299 || authKeyResp.data?.status !== AuthKeyResDtoStatusEnum.Success) {
      history.push('/problem');
      return;
    }

    const { authKey } = authKeyResp.data;
    const selectedPaymentDetail = selectedStorage.paymentOptionData.find((p) => p.paymentType === selectedPaymentType);

    const updateResult = await api.orderApi.ordersControllerUpdateOrderStatus(
      order.data.orderId,
      {
        customerAndPropertyDetail: {
          ...customerPropertyDetail,
          isSolarAlreadyInstalled: !!customerPropertyDetail.isSolarAlreadyInstalled,
        },
        amt: 0,
        status: UpdateOrderStatusReqDtoStatusEnum.NotAttempted,
        refId: '',
        selectedOption: {
          pvModuleDetailData: {
            systemKW: 0,
            percentageOfSelfPower: 0,
            estimatedTwentyFiveYearsSavings: 0,
          },
          storageSystemDetailData: selectedStorage.storageSystemDetailData,
          pricePerKwh: 0,
          monthlyPayment: selectedPaymentDetail!.paymentDetail.monthlyPaymentAmount,
        },
        authKey,
      },
      {
        headers: {
          authorization: `Bearer ${authKey}`,
        },
      }
    );

    if (updateResult.status > 299) {
      history.push('/problem');
      return;
    }

    history.push('/thank-you');
  };

  useEffect(() => {
    setButtonStatus(
      selectedPaymentType === PaymentOptionDataDtoImplPaymentTypeEnum.UnknownPleaseAudit ? '' : '--continue'
    );
  }, [selectedPaymentType]);

  return (
    <main className="--main --recommendation">
      <div className="grid-container">
        <h1 className="--max6">Recommended System</h1>
        <h2 className="--max6" style={{ textAlign: 'center' }}>
          Here&apos;s our recommendation based on your energy profile.
        </h2>
        <h2 className="--max6" style={{ textAlign: 'center' }}>
          The system size provided may change based on the final design.
        </h2>
        <form id="swell-ecom-tool" className="swell-ecom-tool --grid-mtop">
          <div className="grid-row --reverse --align-center">
            <div className="col-xs-12 col-lg-6 col-xl-4">
              <div className="grid-row">
                <div className="col-xs-12">
                  <div className="form__range-card">
                    <img alt="icon solar" src={iconSolar} />
                    <div className="__range-card-slider">
                      <label>
                        <span className="__range-slider-value" />
                        12 kW system
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12">
                  <div className="__range-card-toggle">
                    <input
                      type="radio"
                      id="battery-0"
                      name="batteryCount"
                      value="1"
                      className="__range-battery-radio"
                      checked={batteryCount === 0}
                      onChange={() => setBatteryCount(0)}
                    />
                    <label htmlFor="battery-0">
                      <img alt="No Battery" src={iconNoBattery} />
                      Solar Only
                    </label>
                    <input
                      type="radio"
                      id="battery-1"
                      name="batteryCount"
                      value="1"
                      className="__range-battery-radio"
                      checked={batteryCount === 1}
                      onChange={() => setBatteryCount(1)}
                    />
                    <label htmlFor="battery-1">
                      <img alt="One Battery" src={iconBattery} />
                      One Battery
                    </label>
                    <input
                      type="radio"
                      id="battery-2"
                      name="batteryCount"
                      value="2"
                      className="__range-battery-radio"
                      checked={batteryCount === 2}
                      onChange={() => setBatteryCount(2)}
                    />
                    <label htmlFor="battery-2">
                      <img alt="Two Batteries" src={iconBattery2X} />
                      Two Batteries
                    </label>
                  </div>
                </div>
              </div>
              <p className="--note">
                This configuration should fit your needs but you can customize it to better fit your goals. We&apos;ve
                provided a cost estimate. Your energy consultant will help you understand the Federal Tax Credits and if
                you are eligible for a VPP utility program to maximize your savings.
              </p>
            </div>
            <div className="col-xs-12 col-lg-6 col-xl-8">
              <DynamicHome panelPct={1} batteryCount={batteryCount} />
            </div>
          </div>

          <div className="recommendation__payment-container">
            <div className="recommendation__payments col-xs-12">
              <div className="payment-buttons">
                {loanPayment && (
                  <button
                    type="button"
                    className="btn payment__button"
                    onClick={() => setSelectedPaymentType(loanPayment!.paymentType)}
                  >
                    <label className="--lg-payment" htmlFor="paymentLoan">
                      <input
                        type="radio"
                        name="payment"
                        id="paymentLoan"
                        checked={selectedPaymentType === loanPayment.paymentType}
                        onChange={() => setSelectedPaymentType(loanPayment!.paymentType)}
                      />
                      <span className="__payment-type">
                        <h4>Finance Options</h4>
                        <span className="__payment-price flex --column --justify-center --align-middle">
                          <span>{usdFormatter.format(Math.round(loanPayment.paymentDetail.monthlyPaymentAmount))}</span>
                          month
                        </span>
                      </span>
                    </label>
                  </button>
                )}
                {cashPayment && (
                  <button
                    type="button"
                    className="btn payment__button"
                    onClick={() => setSelectedPaymentType(cashPayment.paymentType)}
                  >
                    <label className="--lg-payment" htmlFor="paymentCash">
                      <input
                        type="radio"
                        name="payment"
                        id="paymentCash"
                        checked={selectedPaymentType === cashPayment.paymentType}
                        onChange={() => setSelectedPaymentType(cashPayment.paymentType)}
                      />
                      <span className="__payment-type">
                        <h4>Cash</h4>
                        <span className="__payment-price flex --column --justify-center --align-middle">
                          <span>{usdFormatter.format(Math.round(cashPayment.paymentDetail.monthlyPaymentAmount))}</span>
                          one-time
                        </span>
                      </span>
                    </label>
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="menu-form">
            <Link className="btn --back" to="vpp">
              <span className="icon-back" />
            </Link>
            <button
              className={`btn ${isSubmitting ? '' : buttonStatus}`}
              type="button"
              onClick={doReserveSystem}
              disabled={isSubmitting}
            >
              Continue
            </button>
            <Link className="btn --restart" to="/">
              <span className="icon-restart" />
            </Link>
          </div>
        </form>
      </div>
    </main>
  );
}
export default PFRecommendation;
