import { Configuration, OrderApi, VisitApi } from './api';
import leadApi from './lead';

const baseURL = process.env.REACT_APP_ECOMMERCE_API_BASE;
const apiConfig = new Configuration();
apiConfig.basePath = baseURL;

const visitApi = new VisitApi(apiConfig);
const orderApi = new OrderApi(apiConfig);

export * as Api from './api';

export default {
  visitApi,
  orderApi,
  leadApi,
};
