import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_MAP_CONFIG } from '../../components/google_map_config';
import { UserAddress } from '../../reducer/typing';

const initialState: UserAddress = {
  address: '',
  appt: null,
  city: '',
  state: '',
  zip: null,
  lat: DEFAULT_MAP_CONFIG.center.lat,
  lng: DEFAULT_MAP_CONFIG.center.lng,
};

const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    addUserAddress: {
      reducer(state, { payload }: PayloadAction<UserAddress>) {
        Object.assign(state, payload);
      },
      prepare: (obj: UserAddress) => {
        return { payload: obj };
      },
    },
    updateAppNumber: {
      reducer(state, { payload }: PayloadAction<string>) {
        state.appt = payload;
      },
      prepare: (appt: string) => {
        return { payload: appt };
      },
    },
  },
});

const selectAddress = (state: { userAddress: UserAddress }) => state.userAddress;
export const selectUserAddress = createSelector([selectAddress], (address) => address);
export const { addUserAddress, updateAppNumber } = addressSlice.actions;
export default addressSlice.reducer;
