import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

import slide2 from '../../assets/img/edu/2_lg.jpg';
import slide3 from '../../assets/img/edu/3.jpg';
import slide4 from '../../assets/img/edu/4_lg.jpg';

SwiperCore.use([Pagination, Navigation]);

function EduTopicsLG() {
  const { hash, search } = useLocation();
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
  let returnUrl = 'contact-info';

  if (search) {
    const parsed = queryString.parse(search);

    if (parsed.from && parsed.from !== '/edu-intro' && typeof parsed.from === 'string') {
      returnUrl = parsed.from;
    }
  }

  useEffect(() => {
    if (hash && hash.length > 1 && /\d/.test(hash.substr(1)) && swiperInstance) {
      swiperInstance.slideTo(Number(hash.substr(1)), 500);
    }
  }, [hash, swiperInstance]);

  return (
    <main className="--main --edu-topics">
      <div className="grid-container">
        <Swiper pagination={{ clickable: true }} onSwiper={(swiper) => setSwiperInstance(swiper)} navigation>
          <SwiperSlide>
            <div className="grid-row --reverse">
              <div className="col-xs-12 col-md-6">
                <h1>Increase your energy confidence</h1>
                <p>
                  With climate events and natural disasters contributing to increased stress on our power grid, having a
                  dependable and affordable energy source is more critical than ever before.
                </p>
                <p>
                  Home energy systems from LG ThinQ Energy combine solar panels, battery storage, and smart devices to
                  help you generate and use affordable power that is clean and reliable. Together we can decrease our
                  reliance on fossil-fuel-based energy infrastructure.
                </p>
              </div>
              <div className="col-xs-12 col-md-6">
                <img src={slide2} alt="" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="grid-row --reverse">
              <div className="col-xs-12 col-md-6">
                <h1>Increase your energy savings</h1>
                <p>
                  Sign up today and be among the first 1,500 customers in California to purchase a home energy system
                  from LG Electronics and enjoy the cost-avoidance benefits you get by using your battery during
                  &ldquo;peak periods&rdquo; in Time-of-Use programs.
                </p>
                <p>
                  And, save even more by enrolling in LG’s ThinQ Energy Virtual Power Plant (VPP), earning a guaranteed
                  $50* in your first year after joining the ThinQ Energy VPP.
                </p>
                <p className="--note">
                  * Activated when at least 500 customers are enrolled in each of PG&E, SCE, and SDG&E territories
                </p>
              </div>
              <div className="col-xs-12 col-md-6">
                <img src={slide3} alt="" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="grid-row --reverse">
              <div className="col-xs-12 col-md-6">
                <h1>Increase your energy efficiency</h1>
                <p>Choosing LG ThinQ Energy to manage your home&apos;s energy is pretty smart.</p>
                <p>
                  What&apos;s even smarter is letting our system do all the heavy lifting – saving you energy and saving
                  you money by minimizing how much grid power your home uses during peak periods. This lowers your
                  electric bill while keeping your backup power ready for when you need it.
                </p>
              </div>
              <div className="col-xs-12 col-md-6">
                <img src={slide4} alt="" />
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <div className="menu-form">
          <Link className="btn --continue" to={returnUrl}>
            Continue
          </Link>
        </div>
      </div>
    </main>
  );
}

export default EduTopicsLG;
