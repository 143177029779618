import GoogleMapReact, { Coords } from 'google-map-react';
import React, { useEffect, useState } from 'react';
import Slider from 'react-rangeslider';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { selectUserAddress } from '../../feature/address/address';
import { selectUserInfor, updatePropertyAndMonthlyBill } from '../../feature/eduIntro/eduIntro';
import { DEFAULT_MAP_CONFIG } from '../google_map_config';
import api from '../../services';
import { getIsPropertyOwned, getVisitDto } from '../../utils';
import { AddVisitDto } from '../../services/api';

function ServiceAreaSuccess(props: any) {
  const userInfo = useSelector(selectUserInfor);
  const userGoogleAddress = useSelector(selectUserAddress);
  const { address: addressProps } = props;
  const { address, appt, city, state, zip } = addressProps;

  const history = useHistory();
  const dispatch = useDispatch();

  const [buttonStatus, setButtonStatus] = useState('--error');
  const [avgMonthlyUtilityBill, setAvgMonthlyUtilityBill] = useState(userInfo.avgMonthlyUtilityBill);
  const [isOwnProperty, setIsOwnProperty] = useState(userInfo.isOwnProperty);

  useEffect(() => {
    if (!(userInfo.isOwnProperty == null)) {
      setButtonStatus('--continue');
    }
    setAvgMonthlyUtilityBill(userInfo.avgMonthlyUtilityBill);
    setIsOwnProperty(userInfo.isOwnProperty);
  }, [userInfo]);

  const handleClickInput = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (buttonStatus !== '--error') {
      dispatch(updatePropertyAndMonthlyBill({ avgMonthlyUtilityBill, isOwnProperty }));

      const sourceDto = getVisitDto('ServiceAreaSuccess', userInfo, userGoogleAddress, false, 0);
      const reqDto: AddVisitDto = {
        ...sourceDto,
        customerPropertyDetail: {
          ...sourceDto.customerPropertyDetail,
          isPropertyOwned: getIsPropertyOwned(isOwnProperty),
          utilityDetail: {
            averageMonthlyUtilityBill: avgMonthlyUtilityBill || 0,
          },
        },
      };
      api.visitApi.visitControllerAddVisit(reqDto);

      if (isOwnProperty) {
        history.push('/own-solar');
      } else {
        history.push('/ineligible');
      }
    }
  };

  const mapCenter: Coords = userGoogleAddress;

  return (
    <main className="--main --address">
      <div className="grid-container">
        <h1>Great! Just a few more things...</h1>

        <form id="swell-ecom-tool" className="swell-ecom-tool" noValidate>
          <div className="grid-row --grid-mtop --align-center">
            <div className="col-xs-12 col-md-6 col-no-margin-lg">
              <div style={{ height: '300px', width: '100%' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: DEFAULT_MAP_CONFIG.key, libraries: DEFAULT_MAP_CONFIG.libraries }}
                  center={mapCenter}
                  zoom={DEFAULT_MAP_CONFIG.zoom}
                  options={{
                    mapTypeId: DEFAULT_MAP_CONFIG.mapTypeId,
                    tilt: DEFAULT_MAP_CONFIG.tilt,
                    maxZoom: DEFAULT_MAP_CONFIG.maxZoom,
                    scaleControl: true,
                    rotateControl: false,
                    fullscreenControl: false,
                    scrollwheel: false,
                  }}
                />
              </div>
              <address>
                <p>
                  {address} {appt}
                  <br />
                  {city} {state}, {zip}
                </p>
              </address>
            </div>
            <div className="col-xs-12 col-md-6 col-no-margin-lg">
              <fieldset>
                <h2>Do you own this property?</h2>
                <div className="__radio">
                  <label className="--radio" htmlFor="propertyYes">
                    <input
                      type="radio"
                      name="yes"
                      id="propertyYes"
                      checked={isOwnProperty === 1}
                      required
                      onChange={() => {
                        setIsOwnProperty(1);
                        setButtonStatus('--continue');
                      }}
                    />
                    <span>Yes</span>
                  </label>
                  <label className="--radio" htmlFor="propertyNo">
                    <input
                      type="radio"
                      name="no"
                      id="propertyNo"
                      checked={isOwnProperty === 0}
                      onChange={() => {
                        setIsOwnProperty(0);
                        setButtonStatus('--continue');
                      }}
                    />
                    <span>No</span>
                  </label>
                </div>

                <h2>What is your average monthly utility bill?</h2>
                <div className="__range flex --align-center">
                  <input
                    id="rangeInput"
                    className="__range-input"
                    type="number"
                    value={avgMonthlyUtilityBill || 0}
                    name="amount"
                    form="monthlyUtilityBill"
                    min={50}
                    max={700}
                    required
                    onChange={(e) => setAvgMonthlyUtilityBill(Number(e.target.value))}
                  />
                  <Slider
                    className="__range-slider --middle"
                    min={50}
                    max={700}
                    value={avgMonthlyUtilityBill || 50}
                    tooltip={false}
                    onChange={(e) => setAvgMonthlyUtilityBill(e)}
                  />
                  <div className="__range-contain-value">
                    $<span className="__range-slider-value">{avgMonthlyUtilityBill || 0}</span>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>

          <div className="menu-form">
            <Link className="btn --back" to="address">
              <span className="icon-back" />
            </Link>
            <input
              className={`submit btn --submit ${buttonStatus}`}
              type="submit"
              value="Continue"
              onClick={handleClickInput}
            />
            <Link className="btn --restart" to="/">
              <span className="icon-restart" />
            </Link>
          </div>
        </form>
      </div>
    </main>
  );
}

export default ServiceAreaSuccess;
