import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

import slide1 from '../../assets/img/edu/1.jpg';
import slide2 from '../../assets/img/edu/2.jpg';
import slide3 from '../../assets/img/edu/3.jpg';
import slide4 from '../../assets/img/edu/4.jpg';
import slide5 from '../../assets/img/edu/5.jpg';
import slide6 from '../../assets/img/edu/6.jpg';

SwiperCore.use([Pagination, Navigation]);

function EduTopics() {
  const { hash, search } = useLocation();
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
  let returnUrl = 'contact-info';

  if (search) {
    const parsed = queryString.parse(search);

    if (parsed.from && parsed.from !== '/edu-intro' && typeof parsed.from === 'string') {
      returnUrl = parsed.from;
    }
  }

  useEffect(() => {
    if (hash && hash.length > 1 && /\d/.test(hash.substr(1)) && swiperInstance) {
      swiperInstance.slideTo(Number(hash.substr(1)), 500);
    }
  }, [hash, swiperInstance]);

  return (
    <main className="--main --edu-topics">
      <div className="grid-container">
        <Swiper pagination={{ clickable: true }} onSwiper={(swiper) => setSwiperInstance(swiper)} navigation>
          <SwiperSlide>
            <div className="grid-row --reverse">
              <div className="col-xs-12 col-md-6">
                <h1>From the old grid to a new energy future</h1>
                <p>
                  Electricity is changing. From how its made to how its used, there have never been more options. In the
                  past, energy was generated by large, centralized plants (fired by fossil fuels and nuclear reactors)
                  and delivered to homes and businesses over the electric grid, where increased demand drove a constant
                  need to build and fuel power plants.
                </p>
                <p>
                  Today, Swell Energy uses smart grid technologies and Virtual Power Plants (VPPs) to deploy distributed
                  solar powered energy storage when and where it is needed, reducing our dependence on fossil based
                  power plants while modernizing the existing grid to meet the diverse energy needs of today.
                </p>
              </div>
              <div className="col-xs-12 col-md-6">
                <img src={slide1} alt="" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="grid-row --reverse">
              <div className="col-xs-12 col-md-6">
                <h1>The new/better way to power your life</h1>
                <p>
                  The grid is fragile and now exposed to stronger and more frequent natural disasters spurred by climate
                  change. Utilities, homeowners and business owners are seeking dependable and affordable energy
                  resources, and the reliability and assurance that come wtih it.
                </p>
                <p>
                  Swell Energy is working to optimize the grid with distributed energy resources so everyone has access
                  to the right power, at the right price, and at the right time, decreasing the need for new fossil
                  based energy infrastructure.
                </p>
              </div>
              <div className="col-xs-12 col-md-6">
                <img src={slide2} alt="" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="grid-row --reverse">
              <div className="col-xs-12 col-md-6">
                <h1>Savings over time</h1>
                <p>
                  Earn more for the power you produce. Leveraging an energy system for time-of-use price advantages is
                  only one way a solar + storage system can save you money. When these systems also participate in new
                  utility power programs, or Swell’s Virtual Power Plants, homeowners and businessowners get paid for
                  participation while making their neighborhood more energy resilient. Customers win by layering
                  multiple financial incentives available when producing, storing, and consuming energy in smart ways.
                  Oh yes, and the planet wins too.
                </p>
                {process.env.REACT_APP_HIDE_ESA !== 'true' && (
                  <p>
                    The Swell Energy ESA (Energy Services Agreement) is a finance alternative for solar paired with or
                    without energy storage that establishes a fixed monthly rate for your power. Swell Energy’s ESA
                    provides the peace of mind of 24/7 system monitoring, predictable energy bills, and renewable power
                    onsite.
                  </p>
                )}
              </div>
              <div className="col-xs-12 col-md-6">
                <img src={slide3} alt="" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="grid-row --reverse">
              <div className="col-xs-12 col-md-6">
                <h1>Solar, storage, and smarts</h1>
                <p>
                  Generating your own energy, and storing it in a home battery for when the lights go out is pretty
                  smart. What’s smarter is letting Swell Energy technology manage your system so that you can save money
                  when rates are high, earn money by participating in Swell’s Virtual Power Plants, and rest easy
                  knowing you have backup energy available during a power outage.
                </p>
              </div>
              <div className="col-xs-12 col-md-6">
                <img src={slide4} alt="" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="grid-row --reverse">
              <div className="col-xs-12 col-md-6">
                <h1>Joining the Smart Grid</h1>
                <p>
                  Swell Energy’s smart grid technology and Virtual Power Plant (VPP) programs use your neighborhood’s
                  established energy infrastructure and distributed energy storage to empower customers to get the best
                  of all worlds: savings, energy independence, and environmental stewardship.
                </p>
              </div>
              <div className="col-xs-12 col-md-6">
                <img src={slide5} alt="" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="grid-row --reverse">
              <div className="col-xs-12 col-md-6">
                <h1>The Swell Difference</h1>
                <p>
                  Bringing together Solar, Storage, and the Smart Grid for energy independence, security, savings, and a
                  brighter future. The Swell Energy team is always ready to help - from configuring the perfect system
                  to ensuring your install goes smoothly. Use our tool to find the right mix of panels, batteries, and
                  payment to meet your needs. A sales associate is always here to help.
                </p>
              </div>
              <div className="col-xs-12 col-md-6">
                <img src={slide6} alt="" />
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <div className="menu-form">
          <Link className="btn --continue" to={returnUrl}>
            Continue
          </Link>
        </div>
      </div>
    </main>
  );
}

export default EduTopics;
