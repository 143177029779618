import GoogleMapReact, { Coords } from 'google-map-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { addUserAddress, selectUserAddress, updateAppNumber } from '../../feature/address/address';
import { selectUserInfor } from '../../feature/eduIntro/eduIntro';
import { UserAddress } from '../../reducer/typing';
import { getVisitDto } from '../../utils';
import { DEFAULT_MAP_CONFIG } from '../google_map_config';
import api from '../../services';

function Address(props: any) {
  const [didDefaultAddress, setDidDefaultAddress] = useState(false);
  const [mapsInstance, setMapsInstance] = useState<any | null>(null);
  const [isUnableToLocate, setIsUnableToLocate] = useState(false);
  const inputEl = useRef<HTMLInputElement | null>(null);
  const userInfo = useSelector(selectUserInfor);
  const userGoogleAddress = useSelector(selectUserAddress);
  const dispatch = useDispatch();
  const history = useHistory();

  const mapCenter: Coords = {
    lat: userGoogleAddress?.lat || DEFAULT_MAP_CONFIG.center.lat,
    lng: userGoogleAddress?.lng || DEFAULT_MAP_CONFIG.center.lng,
  };

  const addressBox = inputEl?.current;
  const { address } = userGoogleAddress;

  useEffect(() => {
    if (!addressBox || !mapsInstance) {
      return;
    }

    const searchBox = new mapsInstance.maps.places.Autocomplete(addressBox, {
      types: ['address'],
      fields: ['address_components', 'geometry'],
    });

    searchBox.addListener('place_changed', () => {
      const place = searchBox.getPlace();

      if (!place) return;

      const findComponent = (name: string) => place.address_components?.find((c: any) => c.types[0] === name);
      const payload: UserAddress = {
        address: `${findComponent('street_number')?.long_name} ${findComponent('route')?.short_name}`,
        city: findComponent('locality')?.long_name,
        state: findComponent('administrative_area_level_1')?.short_name,
        zip: findComponent('postal_code')?.long_name,
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng(),
      };

      if (
        payload.address &&
        !payload.address.startsWith('undefined') &&
        payload.city &&
        payload.state &&
        payload.zip &&
        payload.lat &&
        payload.lng
      ) {
        dispatch(addUserAddress(payload));
        addressBox.value = payload.address;
        setIsUnableToLocate(false);
      } else {
        setIsUnableToLocate(true);
        addressBox.value = '';
      }
    });

    // eslint-disable-next-line consistent-return
    return () => mapsInstance.maps.event.clearInstanceListeners(searchBox);
  }, [addressBox, mapsInstance]);

  useEffect(() => {
    if (addressBox && address && !didDefaultAddress) {
      addressBox.value = address;
      setDidDefaultAddress(true);
    }
  }, [didDefaultAddress, addressBox, address]);

  const onSubmit = async () => {
    api.visitApi.visitControllerAddVisit(getVisitDto('Address', userInfo, userGoogleAddress, false, 0, false));
    history.push('/service-area-success');
  };

  const isValid =
    userGoogleAddress.address &&
    userGoogleAddress.city &&
    userGoogleAddress.state &&
    userGoogleAddress.zip &&
    userGoogleAddress.lat &&
    userGoogleAddress.lng;

  return (
    <main className="--main --address">
      <div className="grid-container">
        <h1>OK, let’s get some details on your home.</h1>
        <form className="swell-ecom-tool" id="swell-ecom-tool" onSubmit={(e) => e.preventDefault()}>
          <div className="grid-row --grid-mtop --align-center">
            <div className="col-xs-12 col-md-6 col-no-margin-lg">
              <fieldset>
                <div className="__address flex --wrap">
                  <div className="flex --address-row">
                    <div className="__street">
                      <label className="__label" htmlFor="address">
                        Street Address
                      </label>
                      <input
                        type="text"
                        name="address"
                        id="address"
                        required
                        placeholder="Street Address"
                        ref={inputEl}
                        autoComplete="off"
                      />
                    </div>

                    <div className="__apt">
                      <label className="__label" htmlFor="address">
                        Apt. #/Suite
                      </label>
                      <input
                        name="address"
                        id="address"
                        placeholder="Apt #"
                        autoComplete="off"
                        value={userGoogleAddress.appt || ''}
                        onChange={(e) => dispatch(updateAppNumber(e.currentTarget.value))}
                      />
                    </div>
                  </div>

                  {isUnableToLocate && (
                    <div className="flex --address-row">
                      <Link to="/problem" className="__address-problem">
                        Having some trouble finding your address?
                      </Link>
                    </div>
                  )}

                  <div className="flex --address-row">
                    <div className="__city --valid">
                      <label className="__label">City</label>
                      <input type="text" readOnly value={userGoogleAddress.city || ''} />
                    </div>

                    <div className="__state">
                      <label className="__label">State</label>
                      <input type="text" readOnly value={userGoogleAddress.state || ''} />
                    </div>

                    <div className="__zip">
                      <label className="__label">Zip</label>
                      <input type="text" readOnly value={`${userGoogleAddress.zip || ''}`} />
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="col-xs-12 col-md-6 col-no-margin-lg">
              <GoogleMapReact
                bootstrapURLKeys={{ key: DEFAULT_MAP_CONFIG.key, libraries: DEFAULT_MAP_CONFIG.libraries }}
                center={mapCenter}
                zoom={DEFAULT_MAP_CONFIG.zoom}
                options={{
                  mapTypeId: DEFAULT_MAP_CONFIG.mapTypeId,
                  tilt: DEFAULT_MAP_CONFIG.tilt,
                  maxZoom: DEFAULT_MAP_CONFIG.maxZoom,
                  scaleControl: true,
                  rotateControl: false,
                  fullscreenControl: false,
                  scrollwheel: false,
                }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={(googleInstance: any) => {
                  setMapsInstance(googleInstance);
                }}
                style={{ height: '300px' }}
              />
            </div>
          </div>
          <div className="menu-form">
            <Link className="btn --back" to="contact-info">
              <span className="icon-back" />
            </Link>
            <input
              className={`submit btn --submit ${isValid ? '--continue' : '--error'} `}
              type="submit"
              value="Continue"
              disabled={!isValid}
              onClick={() => onSubmit()}
            />
            <Link className="btn --restart" to="/">
              <span className="icon-restart" />
            </Link>
          </div>
        </form>
      </div>
    </main>
  );
}

export default Address;
