import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPropertyAndMonthlyBill, ISolarInstalledAndSizeInWat, IUserInfor, IVPPInterest } from '../../reducer/typing';
import { MotivatorDtoMotivatorDataOrderEnum, StoragePreferenceDtoStoragePreferenceEnum } from '../../services/api';

const initialState: IUserInfor = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  contactAgree: false,
  avgMonthlyUtilityBill: 0,
  isOwnProperty: null,
  isSolarAlreadyInstalled: undefined,
  isInterestedInSolar: undefined,
  isInterestedInVPP: undefined,
  existingSolarSystemSizeInWat: 0,
  customerPreferencesDetailData: {
    motivatorPreference: {
      motivatorDataOrder: [
        MotivatorDtoMotivatorDataOrderEnum.SelfPowered,
        MotivatorDtoMotivatorDataOrderEnum.MaximumSavings,
        MotivatorDtoMotivatorDataOrderEnum.BackupPower,
        MotivatorDtoMotivatorDataOrderEnum.ReducedPollution,
      ],
    },
    storagePreference: {
      storagePreference: StoragePreferenceDtoStoragePreferenceEnum.ImNotSure,
    },
    appliances: [],
  },
};

interface IUpdateStoragePreferences {
  storagePreference: StoragePreferenceDtoStoragePreferenceEnum;
  appliances: string[];
}

const eduIntroSlice = createSlice({
  name: 'eduIntro',
  initialState,
  reducers: {
    addUserInfor: {
      reducer(state, { payload }: PayloadAction<IUserInfor>) {
        Object.assign(state, payload);
      },
      prepare: (obj: IUserInfor) => {
        return { payload: obj };
      },
    },
    updatePropertyAndMonthlyBill: {
      reducer(state, { payload }: PayloadAction<IPropertyAndMonthlyBill>) {
        state.avgMonthlyUtilityBill = payload.avgMonthlyUtilityBill;
        state.isOwnProperty = payload.isOwnProperty;
      },
      prepare: (obj: IPropertyAndMonthlyBill) => {
        return { payload: obj };
      },
    },
    updateSolarInstalledAndSizeInWat: {
      reducer(state, { payload }: PayloadAction<ISolarInstalledAndSizeInWat>) {
        state.isSolarAlreadyInstalled = payload.isSolarAlreadyInstalled;
        state.existingSolarSystemSizeInWat = payload.existingSolarSystemSizeInWat;
        state.isInterestedInSolar = payload.isInterestedInSolar;
      },
      prepare: (obj: ISolarInstalledAndSizeInWat) => {
        return { payload: obj };
      },
    },
    updateMotivatorOrder: {
      reducer(state, { payload }: PayloadAction<MotivatorDtoMotivatorDataOrderEnum[]>) {
        // let newMotivatorOrder = payload.slice();
        state.customerPreferencesDetailData.motivatorPreference = { motivatorDataOrder: payload };
      },
      prepare: (obj: MotivatorDtoMotivatorDataOrderEnum[]) => {
        return { payload: obj };
      },
    },
    updateStoragePreferences: {
      reducer(state, { payload: { appliances, storagePreference } }: PayloadAction<IUpdateStoragePreferences>) {
        state.customerPreferencesDetailData.storagePreference = { storagePreference };
        state.customerPreferencesDetailData.appliances = appliances;
      },
      prepare: (obj: StoragePreferenceDtoStoragePreferenceEnum, appliances: string[]) => {
        return { payload: { storagePreference: obj, appliances } };
      },
    },
    updateVPPInterest: {
      reducer(state, { payload }: PayloadAction<IVPPInterest>) {
        state.isInterestedInVPP = payload.isInterestedInVPP;
      },
      prepare: (obj: IVPPInterest) => {
        return { payload: obj };
      },
    },
  },
});
const selectUser = (state: { userInfor: IUserInfor }) => state.userInfor;
export const selectUserInfor = createSelector(selectUser, (user) => user);

export const {
  addUserInfor,
  updateMotivatorOrder,
  updatePropertyAndMonthlyBill,
  updateSolarInstalledAndSizeInWat,
  updateStoragePreferences,
  updateVPPInterest,
} = eduIntroSlice.actions;
export default eduIntroSlice.reducer;
