import React from 'react';
import { Link } from 'react-router-dom';
import LandingHero from '../../assets/img/penfed/hero.jpg';

function EduIntroPF() {
  return (
    <main className="--main --sunny --center">
      <div className="grid-container">
        <h1>Security for Your Energy Future</h1>
        <h2 className="--max6">
          Whether you need to save money, reduce dependence on fossil fuels, or provide energy security for your family,
          we have your back every step of the way.
        </h2>
        <div className="--col-12 --hero">
          <img src={LandingHero} alt="" />
        </div>

        <div className="menu-form">
          <Link className="btn --continue" to="edu-topics">
            Learn More
          </Link>
          <Link className="btn --continue" to="contact-info">
            Get Started
          </Link>
        </div>
      </div>
    </main>
  );
}

export default EduIntroPF;
