import React from 'react';
import { useLocation } from 'react-router';
import { getFooterBaseClassName, getFooterLeft, getFooterRight } from '../AppTheme';
import { getFooterComponent, withoutHeaderPages } from '../AppThemeComponents';

const CircleFooter = getFooterComponent();
const footerClass = getFooterBaseClassName();

function Footer() {
  const { pathname } = useLocation();

  if (withoutHeaderPages.includes(pathname)) {
    return null;
  }

  return (
    <footer className={footerClass}>
      <CircleFooter />
      <div className="footer__container">
        <p className="leftText">{getFooterLeft()}</p>
        <p>{getFooterRight()}</p>
      </div>
    </footer>
  );
}

export default Footer;
