import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Slider from 'react-rangeslider';
import iconSolar from '../../assets/img/icon-solar-panel.svg';
import iconNoBattery from '../../assets/img/icon-no-battery.svg';
import iconBattery from '../../assets/img/icon-tesla-battery.svg';
import iconBattery2X from '../../assets/img/icon-tesla-battery-2x.svg';
import { selectUserInfor } from '../../feature/eduIntro/eduIntro';
import { selectSystemQuotes } from '../../feature/recommendation/recommendation';
import {
  AuthKeyResDtoStatusEnum,
  CostDetailDataDtoImplEnergyServiceTypeEnum,
  CreateOrderRequestDtoSelectedPaymentTypeEnum,
  CreateOrderResDtoStatusEnum,
  MotivatorDtoMotivatorDataOrderEnum,
  PaymentOptionDataDtoImplPaymentTypeEnum,
  StoragePreferenceDtoStoragePreferenceEnum,
  UpdateOrderStatusReqDtoStatusEnum,
} from '../../services/api';
import api from '../../services';
import { selectUserAddress } from '../../feature/address/address';
import DynamicHome from './DynamicHome';
import ESAComparisonTable from './ESAComparisonTable';

function Recommendation() {
  const history = useHistory();
  const userInfor = useSelector(selectUserInfor);
  const userAddress = useSelector(selectUserAddress);
  const recommendation = useSelector(selectSystemQuotes)!;
  const {
    email,
    firstName,
    lastName,
    phone,
    customerPreferencesDetailData,
    isOwnProperty,
    avgMonthlyUtilityBill,
  } = userInfor;
  const { address, appt, city, state, zip, lat, lng } = userAddress;
  const [isDetailView, setIsDetailView] = useState(false);
  const [batteryCount, setBatteryCount] = useState(1);
  const [hasSetInitialBattery, setHasSetInitialBattery] = useState(false);
  const [panelCount, setPanelCount] = useState(0);
  const [selectedPaymentType, setSelectedPaymentType] = useState(
    PaymentOptionDataDtoImplPaymentTypeEnum.UnknownPleaseAudit
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const usdFormatterOpts = { style: 'currency', currency: 'USD', minimumFractionDigits: 0 };
  const usdFormatter = new Intl.NumberFormat(undefined, usdFormatterOpts);

  const isValidPaymentType = () => {
    return [
      PaymentOptionDataDtoImplPaymentTypeEnum.Cash,
      PaymentOptionDataDtoImplPaymentTypeEnum.Loan,
      PaymentOptionDataDtoImplPaymentTypeEnum.LeaseSolarOnly,
      PaymentOptionDataDtoImplPaymentTypeEnum.LeaseCompleteBackup,
      PaymentOptionDataDtoImplPaymentTypeEnum.LeaseEssentialBackup,
      PaymentOptionDataDtoImplPaymentTypeEnum.LeaseWholeHomeBackup,
    ].includes(selectedPaymentType);
  };

  const motivatorTitle: Record<MotivatorDtoMotivatorDataOrderEnum, string> = {
    [MotivatorDtoMotivatorDataOrderEnum.SelfPowered]: 'Self-Powered',
    [MotivatorDtoMotivatorDataOrderEnum.MaximumSavings]: 'Maximum Savings',
    [MotivatorDtoMotivatorDataOrderEnum.BackupPower]: 'Backup Power',
    [MotivatorDtoMotivatorDataOrderEnum.ReducedPollution]: 'Reduced Pollution',
  };

  const motivatorExtra: Record<MotivatorDtoMotivatorDataOrderEnum, string> = {
    [MotivatorDtoMotivatorDataOrderEnum.SelfPowered]:
      'Your Swell smart home energy system is designed to eliminate dependence on the grid - but take full advantage of a connection to it while providing backup.',
    [MotivatorDtoMotivatorDataOrderEnum.MaximumSavings]:
      'Your Swell smart home energy system is designed to maximize savings by intelligently balancing your generated power, rate changes, and backup, to spend the least amount possible.',
    [MotivatorDtoMotivatorDataOrderEnum.BackupPower]:
      'Your Swell smart home energy system is designed to ensure that you have backup power at the ready should the unexpected occur, while saving money and reducing pollution.',
    [MotivatorDtoMotivatorDataOrderEnum.ReducedPollution]:
      'Your Swell smart home energy system is designed to reduce carbon emissions while providing the peace of mind of energy backup.',
  };

  const batteryTitle: Record<StoragePreferenceDtoStoragePreferenceEnum, string> = {
    [StoragePreferenceDtoStoragePreferenceEnum.EssentialBackup]: 'Essential Backup',
    [StoragePreferenceDtoStoragePreferenceEnum.WholeHomeBackup]: 'Independence Backup',
    [StoragePreferenceDtoStoragePreferenceEnum.ImNotSure]: 'Recommended Storage',
    [StoragePreferenceDtoStoragePreferenceEnum.ImNotInterested]: 'Recommended Storage',
  };

  const batteryTypeExtra: Record<StoragePreferenceDtoStoragePreferenceEnum, string> = {
    [StoragePreferenceDtoStoragePreferenceEnum.EssentialBackup]:
      'We’ve designed your smart home energy system to allow for solar generation and essential backup - the basics to keep your food fresh, your home warm - or cool, water flowing, and a few conveniences.',
    [StoragePreferenceDtoStoragePreferenceEnum.WholeHomeBackup]:
      'We’ve designed your smart home energy system for solar generation and to provide a complete backup of all your electricity needs in the event of a power outage.',
    [StoragePreferenceDtoStoragePreferenceEnum.ImNotSure]:
      'We designed your system with solar generation and essential backup for the time being, but your Swell Energy Consultant will explain the benefits and features of your options to meet your needs.',
    [StoragePreferenceDtoStoragePreferenceEnum.ImNotInterested]:
      'We designed your system for solar generation. A Swell Energy Consultant can help you if you change your mind and advise you on how to best set up your system if you’re thinking of adding a battery later.',
  };

  const paymentTypeMap: Record<
    PaymentOptionDataDtoImplPaymentTypeEnum,
    CreateOrderRequestDtoSelectedPaymentTypeEnum
  > = {
    [PaymentOptionDataDtoImplPaymentTypeEnum.UnknownPleaseAudit]:
      CreateOrderRequestDtoSelectedPaymentTypeEnum.UnknownPleaseAudit,
    [PaymentOptionDataDtoImplPaymentTypeEnum.Cash]: CreateOrderRequestDtoSelectedPaymentTypeEnum.Cash,
    [PaymentOptionDataDtoImplPaymentTypeEnum.Loan]: CreateOrderRequestDtoSelectedPaymentTypeEnum.Loan,
    [PaymentOptionDataDtoImplPaymentTypeEnum.LeaseSolarOnly]:
      CreateOrderRequestDtoSelectedPaymentTypeEnum.LeaseSolarOnly,
    [PaymentOptionDataDtoImplPaymentTypeEnum.LeaseEssentialBackup]:
      CreateOrderRequestDtoSelectedPaymentTypeEnum.LeaseEssentialBackup,
    [PaymentOptionDataDtoImplPaymentTypeEnum.LeaseWholeHomeBackup]:
      CreateOrderRequestDtoSelectedPaymentTypeEnum.LeaseWholeHomeBackup,
    [PaymentOptionDataDtoImplPaymentTypeEnum.LeaseCompleteBackup]:
      CreateOrderRequestDtoSelectedPaymentTypeEnum.LeaseCompleteBackup,
  };

  const paymentTypeEnergyServiceMap: Record<
    PaymentOptionDataDtoImplPaymentTypeEnum,
    CostDetailDataDtoImplEnergyServiceTypeEnum | null
  > = {
    [PaymentOptionDataDtoImplPaymentTypeEnum.UnknownPleaseAudit]: null,
    [PaymentOptionDataDtoImplPaymentTypeEnum.Cash]: null,
    [PaymentOptionDataDtoImplPaymentTypeEnum.Loan]: null,
    [PaymentOptionDataDtoImplPaymentTypeEnum.LeaseSolarOnly]: CostDetailDataDtoImplEnergyServiceTypeEnum.SolarOnly,
    [PaymentOptionDataDtoImplPaymentTypeEnum.LeaseEssentialBackup]:
      CostDetailDataDtoImplEnergyServiceTypeEnum.EssentialBackup,
    [PaymentOptionDataDtoImplPaymentTypeEnum.LeaseWholeHomeBackup]:
      CostDetailDataDtoImplEnergyServiceTypeEnum.WholeHome,
    [PaymentOptionDataDtoImplPaymentTypeEnum.LeaseCompleteBackup]:
      CostDetailDataDtoImplEnergyServiceTypeEnum.CompleteBackup,
  };

  if (!recommendation) {
    history.push('/');
    return <div />;
  }

  const defaultSystem = recommendation.quotes.find((v) => v.isDefault)!;
  const minPanelCount = Math.min(...recommendation.quotes.map((v) => v.numberOfPanels));
  const maxPanelCount = Math.max(...recommendation.quotes.map((v) => v.numberOfPanels));
  const defaultPanelCount = defaultSystem.numberOfPanels;
  const panelCountPct = (panelCount - minPanelCount) / (maxPanelCount - minPanelCount);

  // Use an effect to clamp the panel count
  useEffect(() => {
    if (panelCount < minPanelCount || panelCount > maxPanelCount) {
      setPanelCount(defaultPanelCount);
    }
  }, [minPanelCount, maxPanelCount, defaultPanelCount, panelCount]);

  useEffect(() => {
    if (!hasSetInitialBattery && customerPreferencesDetailData && customerPreferencesDetailData.storagePreference) {
      const preference = customerPreferencesDetailData.storagePreference.storagePreference;
      let initialBatteryCount = 1;

      if (preference === StoragePreferenceDtoStoragePreferenceEnum.WholeHomeBackup) {
        initialBatteryCount = 2;
      } else if (preference === StoragePreferenceDtoStoragePreferenceEnum.ImNotInterested) {
        initialBatteryCount = 0;
      }

      setBatteryCount(initialBatteryCount);
      setHasSetInitialBattery(true);
    }
  }, [customerPreferencesDetailData]);

  useEffect(() => {
    setSelectedPaymentType(PaymentOptionDataDtoImplPaymentTypeEnum.UnknownPleaseAudit);
  }, [batteryCount]);

  const selectedSystem =
    recommendation.quotes.find((r) => r.numberOfPanels === panelCount) ||
    recommendation.quotes.find((r) => r.isDefault)!;

  const defaultSystemKW = Math.round(defaultSystem.pvModuleDetailData.systemKW * 10) / 10;
  const systemKW = Math.round(selectedSystem.pvModuleDetailData.systemKW * 10) / 10;

  const findByStorageCount = (searchForBatteryCount: number) =>
    selectedSystem.storageData.find((s) => s.storageSystemDetailData.storageSystemCount === searchForBatteryCount);

  const selectedStorage = findByStorageCount(batteryCount) || findByStorageCount(1)!;

  let loanPayment = selectedStorage.paymentOptionData.find(
    (p) => p.paymentType === PaymentOptionDataDtoImplPaymentTypeEnum.Loan && p.paymentDetail
  );
  const cashPayment = selectedStorage.paymentOptionData.find(
    (p) => p.paymentType === PaymentOptionDataDtoImplPaymentTypeEnum.Cash && p.paymentDetail
  );
  const leasePayment = selectedStorage.paymentOptionData.find(
    (p) => p.paymentType.startsWith('lease_') && p.paymentDetail
  );

  // TODO: Remove this after demos are done
  if (window.origin.indexOf('shopdemo.swellenergy.com') > -1) {
    loanPayment = undefined;
  }

  const phoneRaw = process.env.REACT_APP_CONTACT_PHONE_NUMBER || '';
  const phoneParts = /1?[ -]?\(?(\d{3})\)?[ -]?(\d{3})[ -]?(\d{4})/.exec(phoneRaw);
  let phoneUnformatted = '';
  let phoneFormatted = '';

  if (phoneParts && phoneParts.length > 0) {
    phoneUnformatted = `1${phoneParts[1]}${phoneParts[2]}${phoneParts[3]}`;
    phoneFormatted = `(${phoneParts[1]}) ${phoneParts[2]}-${phoneParts[3]}`;
  }

  const doReserveSystem = async () => {
    if (selectedPaymentType === PaymentOptionDataDtoImplPaymentTypeEnum.UnknownPleaseAudit) {
      return;
    }

    try {
      setIsSubmitting(true);

      const customerPropertyDetail = {
        visitId: '',
        visitKey: '',
        customerDetail: {
          firstName,
          lastName,
          email,
          phone,
        },
        addressDetail: {
          streetAddress: address,
          aptOrSuite: appt || undefined,
          city: city || '',
          state: state || '',
          zip: zip || 0,
          lat,
          long: lng,
        },
        isPropertyOwned: !!isOwnProperty && isOwnProperty > 0,
        utilityDetail: {
          averageMonthlyUtilityBill: avgMonthlyUtilityBill || 0,
        },
        isSolarAlreadyInstalled: false,
        existingSolarSystemSizeInWatt: 0,
      };

      const order = await api.orderApi.ordersControllerCreateOrder({
        selectedPaymentType: paymentTypeMap[selectedPaymentType],
        amount: 0,
        customerPropertyDetail,
      });

      if (order.status > 299 || order.data?.status !== CreateOrderResDtoStatusEnum.Success) {
        history.push('/problem');
        return;
      }

      const authKeyResp = await api.orderApi.ordersControllerGetPaymentAuthorizationKey(order.data.orderId);

      if (authKeyResp.status > 299 || authKeyResp.data?.status !== AuthKeyResDtoStatusEnum.Success) {
        history.push('/problem');
        return;
      }

      const { authKey } = authKeyResp.data;
      const selectedCostDetail = selectedStorage.costDetailsData.find(
        (c) => c.energyServiceType === paymentTypeEnergyServiceMap[selectedPaymentType]
      );
      const selectedPaymentDetail = selectedStorage.paymentOptionData.find(
        (p) => p.paymentType === selectedPaymentType
      );

      const updateResult = await api.orderApi.ordersControllerUpdateOrderStatus(
        order.data.orderId,
        {
          customerAndPropertyDetail: customerPropertyDetail,
          amt: 0,
          status: UpdateOrderStatusReqDtoStatusEnum.NotAttempted,
          refId: '',
          selectedOption: {
            pvModuleDetailData: selectedSystem.pvModuleDetailData,
            storageSystemDetailData: selectedStorage.storageSystemDetailData,
            pricePerKwh: selectedCostDetail?.quoteDetail?.pricePerKwh || 0,
            monthlyPayment: selectedPaymentDetail?.paymentDetail.monthlyPaymentAmount || 0,
          },
          authKey,
        },
        {
          headers: {
            authorization: `Bearer ${authKey}`,
          },
        }
      );

      if (updateResult.status > 299) {
        history.push('/problem');
        return;
      }

      if (typeof window.dataLayer === 'object') {
        try {
          window.dataLayer.push({
            event: 'submit_reservation',
            ecommerce: {
              currency: 'USD',
              transaction_id: order.data.orderId,
              value: cashPayment?.paymentDetail.monthlyPaymentAmount,
              items: [
                {
                  item_id: `SOLAR_STORAGE_${selectedPaymentDetail?.paymentType}`,
                  item_name: selectedPaymentDetail?.paymentType,
                  price: selectedPaymentDetail?.paymentDetail.monthlyPaymentAmount,
                },
                {
                  item_id: 'SOLAR_PANEL',
                  item_name: 'Solar Panel',
                  quantity: selectedSystem.numberOfPanels,
                },
                {
                  item_id: 'ENERGY_STORAGE',
                  item_name: `Energy Storage`,
                  quantity: selectedStorage.storageSystemDetailData.storageSystemCount,
                },
              ],
            },
          });
        } catch (gtagErr) {
          Sentry.captureException(gtagErr);
        }
      }

      history.push('/thank-you');
    } catch (err) {
      Sentry.captureException(err);
      history.push('/problem');
    }
  };

  return (
    <main className={`--main --recommendation ${isDetailView ? '--detail' : '--simple'}`}>
      <div className="grid-container">
        <h1 className="--max6">Here’s our recommendation and a new way to think about and pay for power.</h1>

        <form id="swell-ecom-tool" className="swell-ecom-tool --grid-mtop">
          <div className="grid-row --reverse --align-center">
            <div className="col-xs-12 col-lg-6 col-xl-4">
              <div className="grid-row">
                <div className="col-xs-12">
                  <div className="form__range-card">
                    <img alt="icon solar" src={iconSolar} />
                    <div className="__range-card-slider">
                      <label>
                        <span className="__range-slider-value" />
                        {systemKW} kW system
                      </label>
                      <label className={`__recommended ${systemKW === defaultSystemKW ? '--yes' : '--no'}`}>
                        ({defaultSystemKW} kW recommended)
                      </label>
                      <Slider
                        className="__range-slider"
                        min={minPanelCount}
                        max={maxPanelCount}
                        value={panelCount}
                        onChange={(val) => setPanelCount(val)}
                        tooltip={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xs-12">
                  <div className="__range-card-toggle">
                    <input
                      type="radio"
                      id="battery-0"
                      name="batteryCount"
                      value="1"
                      className="__range-battery-radio"
                      checked={batteryCount === 0}
                      onChange={() => setBatteryCount(0)}
                    />
                    <label htmlFor="battery-0">
                      <img alt="No Battery" src={iconNoBattery} />
                      Solar Only
                    </label>
                    <input
                      type="radio"
                      id="battery-1"
                      name="batteryCount"
                      value="1"
                      className="__range-battery-radio"
                      checked={batteryCount === 1}
                      onChange={() => setBatteryCount(1)}
                    />
                    <label htmlFor="battery-1">
                      <img alt="One Battery" src={iconBattery} />
                      One Tesla Battery
                    </label>
                    <input
                      type="radio"
                      id="battery-2"
                      name="batteryCount"
                      value="2"
                      className="__range-battery-radio"
                      checked={batteryCount === 2}
                      onChange={() => setBatteryCount(2)}
                    />
                    <label htmlFor="battery-2">
                      <img alt="Two Batteries" src={iconBattery2X} />
                      Two Tesla Batteries
                    </label>
                  </div>
                </div>
              </div>
              <p className="--note">
                This configuration should fit your needs. To customize the recommended solar sizing above, slide the bar
                in either direction. Above is our recommendation for storage, you can manually select an option of your
                choice.
              </p>
            </div>
            <div className="col-xs-12 col-lg-6 col-xl-8">
              <DynamicHome panelPct={panelCountPct} batteryCount={batteryCount} />
            </div>
          </div>

          <div className="grid-row --grid-mtop">
            <div className="col-xs-6">
              <div className="recommendation__programs-card">
                <p>{motivatorTitle[customerPreferencesDetailData.motivatorPreference.motivatorDataOrder[0]]}</p>
                <p className="--note">
                  {motivatorExtra[customerPreferencesDetailData.motivatorPreference.motivatorDataOrder[0]]}
                </p>
              </div>
            </div>
            <div className="col-xs-6">
              <div className="recommendation__programs-card">
                <p>{batteryTitle[customerPreferencesDetailData.storagePreference.storagePreference]}</p>
                <p className="--note">
                  {batteryTypeExtra[customerPreferencesDetailData.storagePreference.storagePreference]}
                </p>
              </div>
            </div>
          </div>

          {!process.env.REACT_APP_HIDE_ESA && (
            <ESAComparisonTable
              batteryCount={batteryCount}
              setBatteryCount={setBatteryCount}
              isDetailView={isDetailView}
              setIsDetailView={setIsDetailView}
              selectedSystem={selectedSystem}
            />
          )}

          <div className="recommendation__payment-container">
            <div className="recommendation__payments col-xs-12">
              <h2>Step 1. Select a payment method</h2>
              <div className="payment-buttons">
                {loanPayment && (
                  <button
                    type="button"
                    className="btn payment__button"
                    onClick={() => setSelectedPaymentType(loanPayment!.paymentType)}
                  >
                    <div>
                      <h4>Finance/Loan</h4>
                      <ul>
                        <li>
                          <span className="icon-check" /> $0 money down
                        </li>
                        <li>
                          <span className="icon-check" /> Self-Maintained
                        </li>
                        <li>
                          <span className="icon-check" /> Self-Monitored
                        </li>
                        <li>
                          <span className="icon-check" /> Federal Tax Credits based on tax liability
                        </li>
                        <li>
                          <span className="icon-check" /> SGIP Rebates must be applied for
                        </li>
                      </ul>
                    </div>
                    <label className="--payment" htmlFor="paymentLoan">
                      <input
                        type="radio"
                        name="payment"
                        id="paymentLoan"
                        checked={selectedPaymentType === loanPayment.paymentType}
                        onChange={() => setSelectedPaymentType(loanPayment!.paymentType)}
                      />
                      <span className="__payment-type">
                        <span className="__payment-price flex --column --justify-center --align-middle">
                          <span>{usdFormatter.format(Math.round(loanPayment.paymentDetail.monthlyPaymentAmount))}</span>
                          month
                        </span>
                      </span>
                    </label>
                  </button>
                )}
                {leasePayment && process.env.REACT_APP_HIDE_ESA !== 'true' && (
                  <button
                    type="button"
                    className="btn payment__button"
                    onClick={() => setSelectedPaymentType(leasePayment.paymentType)}
                  >
                    <div>
                      <h4>ESA</h4>
                      <ul>
                        <li>
                          <span className="icon-check" /> $0 money down
                        </li>
                        <li>
                          <span className="icon-check" /> No impact on future borrowing power
                        </li>
                        <li>
                          <span className="icon-check" /> Production Guarantee
                        </li>
                        <li>
                          <span className="icon-check" /> 25 Year Maintenance
                        </li>
                        <li>
                          <span className="icon-check" /> 25 Year Full System Protection
                        </li>
                        <li>
                          <span className="icon-check" /> 24/7 Monitoring
                        </li>
                        <li>
                          <span className="icon-check" /> Automatic enrollment in Grid Rewards
                        </li>
                        <li>
                          <span className="icon-check" /> Battery replacement
                        </li>
                        <li>
                          <span className="icon-check" /> Federal Tax Credits built into pricing
                        </li>
                        <li>
                          <span className="icon-check" /> SGIP Rebates included in price
                        </li>
                      </ul>
                    </div>
                    <label className="--payment" htmlFor="paymentLease">
                      <input
                        type="radio"
                        name="payment"
                        id="paymentLease"
                        checked={selectedPaymentType === leasePayment.paymentType}
                        onChange={() => setSelectedPaymentType(leasePayment.paymentType)}
                      />
                      <span className="__payment-type">
                        <span className="__payment-price flex --column --justify-center --align-middle">
                          <span>
                            {usdFormatter.format(Math.round(leasePayment.paymentDetail.monthlyPaymentAmount))}
                          </span>
                          month
                        </span>
                      </span>
                    </label>
                  </button>
                )}
                {cashPayment && (
                  <button
                    type="button"
                    className="btn payment__button"
                    onClick={() => setSelectedPaymentType(cashPayment.paymentType)}
                  >
                    <div>
                      <h4>Cash</h4>
                      <ul>
                        <li>
                          <span className="icon-check" /> Down payment required
                        </li>
                        <li>
                          <span className="icon-check" /> No impact on future borrowing power
                        </li>
                        <li>
                          <span className="icon-check" /> Self-Maintained
                        </li>
                        <li>
                          <span className="icon-check" /> Self-Monitored
                        </li>
                        <li>
                          <span className="icon-check" /> Federal Tax Credits based on tax liability
                        </li>
                        <li>
                          <span className="icon-check" /> SGIP Rebates must be applied for
                        </li>
                      </ul>
                    </div>
                    <label className="--payment" htmlFor="paymentCash">
                      <input
                        type="radio"
                        name="payment"
                        id="paymentCash"
                        checked={selectedPaymentType === cashPayment.paymentType}
                        onChange={() => setSelectedPaymentType(cashPayment.paymentType)}
                      />
                      <span className="__payment-type">
                        <span className="__payment-price flex --column --justify-center --align-middle">
                          <span>{usdFormatter.format(Math.round(cashPayment.paymentDetail.monthlyPaymentAmount))}</span>
                          one-time
                        </span>
                      </span>
                    </label>
                  </button>
                )}
              </div>
            </div>
            <div className="recommendation__payments col-xs-12">
              <h2>Step 2. Reserve your system</h2>
              <div className="flex --row --align-stretch --wrap">
                <address className="col-xs-12 col-md-6">
                  <p>
                    {firstName} {lastName}
                  </p>
                  <p>
                    {address} {appt} {city}, {state} {zip}
                  </p>
                  <p>{email}</p>
                  <p>{phone}</p>
                </address>
                <div className="recommendation__offer col-xs-12 col-md-6">
                  <p>
                    Submit your reservation today and Swell Energy will include a <strong>$250 dollar discount</strong>.
                  </p>
                  <p>Act fast, this offer is only available for a limited time!</p>
                </div>
              </div>
              <button
                type="button"
                className={`btn __reserve ${isSubmitting || !isValidPaymentType() ? '' : '--valid'}`}
                onClick={() => doReserveSystem()}
                disabled={isSubmitting}
              >
                Reserve my System
              </button>
              <p className="__contact">
                <span className="icon-calendar" /> Not ready to reserve or have questions?&nbsp;
                <a className="__contact-link" href={`tel:${phoneUnformatted}`}>
                  Give us a call at {phoneFormatted}
                </a>
                &nbsp;or&nbsp;
                <a className="__contact-link" href={process.env.REACT_APP_BOOK_APPOINTMENT_LINK}>
                  schedule a meeting to review it on your time
                </a>
                .
              </p>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
}
export default Recommendation;
