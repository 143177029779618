import { combineReducers } from '@reduxjs/toolkit';
import addressReducer from '../feature/address/address';
import eduIntroReducer from '../feature/eduIntro/eduIntro';
import recommendationReducer from '../feature/recommendation/recommendation';

export default combineReducers({
  userInfor: eduIntroReducer,
  userAddress: addressReducer,
  recommendation: recommendationReducer,
});
