import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateVPPInterest } from '../../feature/eduIntro/eduIntro';

function VPP() {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleInterested = async (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(updateVPPInterest({ isInterestedInVPP: true }));
    history.push('/calculating');
  };

  const handleNotInterested = async (e: React.MouseEvent) => {
    e.preventDefault();
    history.push('/calculating');
  };

  return (
    <main className="--main --vpp --center">
      <div className="grid-container">
        <h1>Save even more by enrolling in a Virtual Power Plant for free</h1>
        <p>
          Virtual Power Plants (VPPs) integrate many power sources, like energy storage systems, to provide more
          reliable power supply to the grid and compensate participants for their contributions. Enrollment is free, and
          your home battery qualifies you to participate. By joining, you’ll receive unique financial incentives through
          programs with your local utility.
        </p>
        <p>
          <strong>
            Enroll today and join the community of homeowners leading the clean-energy transition and “Creating a
            Greater Grid for the Greater Good.”
          </strong>
        </p>

        <form id="swell-ecom-tool" className="swell-ecom-tool --grid-mtop" action="vpp">
          <div className="grid-row --grid-mtop">
            <div className="col-xs-12 col-md-6">
              <span className="__card">
                <div className="__content">
                  <span className="__title">How do I get compensated for participating?</span>
                  <span className="__txt">
                    Each VPP program is unique, and compensation depends on the program availability in your area. An
                    Energy Consultant will provide guidance regarding your eligibility and benefits and if selected, you
                    will get a minimum of $50.
                  </span>
                </div>
              </span>
            </div>
            <div className="col-xs-12 col-md-6">
              <span className="__card">
                <div className="__content">
                  <span className="__title">How do I join a Virtual Power Plant Program?</span>
                  <span className="__txt">
                    Once you submit your information an Energy Consultant will pre-qualify you for participation,
                    explain how it works, and answer your questions.
                  </span>
                </div>
              </span>
            </div>
            <div className="col-xs-12 col-md-6">
              <span className="__card">
                <div className="__content">
                  <span className="__title">What are the program requirements?</span>
                  <span className="__txt">
                    Your home must be located in an active program area, must have an energy storage battery, and you
                    may not be enrolled in any other demand response programs.
                  </span>
                </div>
              </span>
            </div>
            <div className="col-xs-12 col-md-6">
              <span className="__card">
                <div className="__content">
                  <span className="__title">How does joining affect my home energy system?</span>
                  <span className="__txt">
                    No additional parts or servicing of your existing system are needed. There is no cost to join and no
                    penalties for canceling.
                  </span>
                </div>
              </span>
            </div>
          </div>

          <div className="menu-form">
            <input
              className="submit btn --submit --continue"
              type="submit"
              value="Continue"
              onClick={handleInterested}
            />
          </div>
        </form>
      </div>
    </main>
  );
}

export default VPP;
