import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import TagManager from 'react-gtm-module';
import App from './App';
import { persistor, store } from './reducer/store';
import reportWebVitals from './reportWebVitals';
import Problem from './components/Problem/Problem';
import { getGTMTagId } from './AppTheme';

const history = createBrowserHistory();
const environment = process.env.REACT_APP_SENTRY_ENVIRONMENT || 'development';
const tracesSampleRate = environment === 'development' ? 1.0 : 0.2;
const apiHost = new URL(process.env.REACT_APP_ECOMMERCE_API_BASE || 'http://localhost:3001');
const tracingOrigins = [apiHost.origin];

Sentry.init({
  dsn: 'https://37893af6c73e4f27897cbcb9d8f555d0@o458648.ingest.sentry.io/5703702',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins,
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  environment,
  tracesSampleRate,
});

const gtmId = getGTMTagId();

if (gtmId) {
  TagManager.initialize({ gtmId });
}

const errorFallback = <Problem />;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Sentry.ErrorBoundary fallback={errorFallback}>
          <Router history={history}>
            <App />
          </Router>
        </Sentry.ErrorBoundary>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
