import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import './assets/styles/scss/main.scss';
import Address from './components/Address/Address';
import Calculating from './components/Calculating/Calculating';
import Ineligible from './components/Ineligible/Ineligible';
import ContactInfo from './components/ContactInfo/ContactInfo';
import OwnSolar from './components/OwnSolar/OwnSolar';
import Preference from './components/Preference/Preference';
import Problem from './components/Problem/Problem';
import ThankYou from './components/ThankYou/ThankYou';
import serviceAreaSuccessContainer from './container/ServiceAreaSuccessContainer/ServiceAreaSuccessContainer';
import Footer from './layout/Footer';
import Header from './layout/Header';
import VPP from './components/VPP/VPP';
import {
  getBatteryPage,
  getEduTopicsPage,
  getIntroPage,
  getRecommendationPage,
  getThankYouAltPage,
  getThankYouPage,
} from './AppThemeComponents';
import LeadCompact from './components/LeadCompact/LeadCompact';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="App">
      <Header />
      <Switch>
        <Route exact path="/" component={getIntroPage()} />
        <Route exact path="/edu-topics" component={getEduTopicsPage()} />
        <Route exact path="/contact-info" component={ContactInfo} />
        <Route exact path="/Address" component={Address} />
        <Route exact path="/service-area-success" component={serviceAreaSuccessContainer} />
        <Route exact path="/own-solar" component={OwnSolar} />
        <Route exact path="/preferences" component={Preference} />
        <Route exact path="/battery" component={getBatteryPage()} />
        <Route exact path="/vpp" component={VPP} />
        <Route exact path="/calculating" component={Calculating} />
        <Route exact path="/recommendation" component={getRecommendationPage()} />
        <Route exact path="/thank-you" component={getThankYouPage()} />
        <Route exact path="/thank-you-alt" component={getThankYouAltPage()} />
        <Route exact path="/problem" component={Problem} />
        <Route exact path="/ineligible" component={Ineligible} />
        <Route exact path="/lg-lead-compact" component={LeadCompact} />
      </Switch>

      <Footer />
    </div>
  );
}

export default App;
