import React from 'react';
import InputMask from 'react-input-mask';
import { IInputProps } from '../../reducer/typing';

const Input = ({
  errorText,
  htmlFor,
  id,
  inputRef,
  label,
  name,
  nameClass,
  onBlur,
  onChange,
  placeholder,
  required,
  type,
  value,
  mask,
}: IInputProps) => {
  const inputProps = {
    type,
    name,
    id,
    required,
    placeholder,
    className: nameClass,
    onChange,
    onBlur,
    ref: inputRef,
    autoComplete: 'off',
    value,
  };

  return (
    <>
      {label ? (
        <label className="__label" htmlFor={htmlFor}>
          {label}
        </label>
      ) : null}
      {!mask && <input {...inputProps} />}
      {!!mask && <InputMask {...inputProps} mask={mask} alwaysShowMask={false} />}
      {errorText ? (
        <label id="firstname-error" className="--error" htmlFor="firstname">
          {errorText}
        </label>
      ) : null}
    </>
  );
};
export default Input;
