import React from 'react';
import { useSelector } from 'react-redux';
import ServiceAreaSuccess from '../../components/ServiceAreaSuccess/ServiceAreaSuccess';
import { selectUserAddress } from '../../feature/address/address';
import { selectUserInfor } from '../../feature/eduIntro/eduIntro';

function ServiceAreaSuccessContainer() {
  const userAddress = useSelector(selectUserAddress);
  const userInfor = useSelector(selectUserInfor);
  return <ServiceAreaSuccess userInfor={userInfor} address={userAddress} />;
}
export default ServiceAreaSuccessContainer;
