import React, { useEffect, useMemo, useState } from 'react';
import Slider from 'react-rangeslider';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { selectUserAddress } from '../../feature/address/address';
import { selectUserInfor, updateSolarInstalledAndSizeInWat } from '../../feature/eduIntro/eduIntro';
import api from '../../services';
import { getVisitDto } from '../../utils';
import { getShouldAlwaysGoToPreferences, getShouldPromptForWantSolar } from '../../AppTheme';

function OwnSolar() {
  const userInfo = useSelector(selectUserInfor);
  const userAddress = useSelector(selectUserAddress);
  const history = useHistory();
  const dispatch = useDispatch();

  const [isSolarAlreadyInstalled, setIsSolarAlreadyInstalled] = useState<boolean | undefined>(
    userInfo.isSolarAlreadyInstalled
  );
  const [existingSolarSystemSizeInWat, setExistingSolarSystemSizeInWat] = useState(
    userInfo.existingSolarSystemSizeInWat || 0
  );
  const [isInterestedInSolar, setIsInterestedInSolar] = useState<boolean | undefined>(userInfo.isInterestedInSolar);

  useEffect(() => {
    setIsSolarAlreadyInstalled(userInfo.isSolarAlreadyInstalled);
    setExistingSolarSystemSizeInWat(userInfo.existingSolarSystemSizeInWat);
    setIsInterestedInSolar(userInfo.isInterestedInSolar);
  }, [userInfo]);

  const solarInputStatus = useMemo(() => (isSolarAlreadyInstalled === true ? 'anim-fade-in' : 'anim-hide'), [
    isSolarAlreadyInstalled,
  ]);
  const solarWantStatus = useMemo(
    () => (getShouldPromptForWantSolar() && isSolarAlreadyInstalled === false ? 'anim-fade-in' : 'anim-hide'),
    [isSolarAlreadyInstalled]
  );

  const buttonStatus = useMemo(
    () =>
      typeof isSolarAlreadyInstalled === 'boolean' && typeof isInterestedInSolar === 'boolean'
        ? '--continue'
        : '--error',
    [isSolarAlreadyInstalled, isInterestedInSolar]
  );

  const handleClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === 'solaryes') {
      setIsSolarAlreadyInstalled(true);
      setIsInterestedInSolar(false);
    } else if (e.target.id === 'solarno') {
      setIsSolarAlreadyInstalled(false);

      if (!getShouldPromptForWantSolar()) {
        setIsInterestedInSolar(true);
      } else {
        setIsInterestedInSolar(undefined);
      }
    } else if (e.target.id === 'wantsolaryes') {
      setIsInterestedInSolar(true);
    } else if (e.target.id === 'wantsolarno') {
      setIsInterestedInSolar(false);
    }
  };

  const handleClickInput = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (buttonStatus === '--continue' && typeof isInterestedInSolar === 'boolean') {
      try {
        const reqBody = getVisitDto(
          'OwnSolar',
          userInfo,
          userAddress,
          isSolarAlreadyInstalled,
          existingSolarSystemSizeInWat,
          isInterestedInSolar
        );

        api.visitApi.visitControllerAddVisit(reqBody);
        dispatch(
          updateSolarInstalledAndSizeInWat({
            isSolarAlreadyInstalled: !!isSolarAlreadyInstalled,
            existingSolarSystemSizeInWat,
            isInterestedInSolar,
          })
        );

        if (getShouldAlwaysGoToPreferences() || !isSolarAlreadyInstalled) {
          history.push('/preferences');
        } else {
          history.push('/calculating');
        }
      } catch (error) {
        Sentry.captureException(error);
        history.push('/problem');
      }
    }
  };

  return (
    <main className="--main --own-solar --center">
      <div className="grid-container">
        <h1>Do you have a solar system at this property?</h1>

        <form id="swell-ecom-tool" className="swell-ecom-tool" action="preferences">
          <fieldset>
            <div className="__radio --center">
              <label className="--radio">
                <input
                  type="radio"
                  name="solar"
                  id="solaryes"
                  checked={isSolarAlreadyInstalled === true}
                  onChange={handleClick}
                />
                <span>Yes</span>
              </label>
              <label className="--radio">
                <input
                  type="radio"
                  name="solar"
                  id="solarno"
                  checked={isSolarAlreadyInstalled === false}
                  onChange={handleClick}
                />
                <span>No</span>
              </label>
            </div>
            <div className={`__how-large ${solarInputStatus} `}>
              <h2>How large is your solar system?</h2>
              <div className="__range --max --center flex --align-center">
                <input
                  id="rangeInput"
                  className="__range-input"
                  type="text"
                  value={existingSolarSystemSizeInWat}
                  name="amount"
                  // htmlFor='monthlyUtilityBill'
                  required
                />
                <Slider
                  className="__range-slider --middle"
                  min={3}
                  max={30}
                  value={existingSolarSystemSizeInWat!}
                  tooltip={false}
                  onChange={(e) => setExistingSolarSystemSizeInWat(e)}
                />
                <div className="__range-contain-value">
                  <span className="__range-slider-value">{existingSolarSystemSizeInWat}</span> kW
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="--grid-mtop">
            <div className={`__want-solar ${solarWantStatus} `}>
              <h1>Are you interested in getting a solar quote?</h1>
              <div className="__radio --center">
                <label className="--radio">
                  <input
                    type="radio"
                    name="wantsolar"
                    id="wantsolaryes"
                    checked={isInterestedInSolar === true}
                    onChange={handleClick}
                  />
                  <span>Yes</span>
                </label>
                <label className="--radio">
                  <input
                    type="radio"
                    name="wantsolar"
                    id="wantsolarno"
                    checked={isInterestedInSolar === false}
                    onChange={handleClick}
                  />
                  <span>No</span>
                </label>
              </div>
            </div>
          </fieldset>

          <div className="menu-form --grid-mtop">
            <Link className="btn --back" to="service-area-success">
              <span className="icon-back" />
            </Link>
            <input
              className={`submit btn --submit ${buttonStatus}`}
              type="submit"
              value="Continue"
              onClick={handleClickInput}
            />

            <Link className="btn --restart" to="/">
              <span className="icon-restart" />
            </Link>
          </div>
        </form>
      </div>
    </main>
  );
}

export default OwnSolar;
