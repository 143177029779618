import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { selectUserInfor, updateStoragePreferences } from '../../feature/eduIntro/eduIntro';
import { StoragePreferenceDtoStoragePreferenceEnum, UpdatePreferenceReqDto } from '../../services/api';
import EssentialBackupImg from '../../assets/img/lg-essential.jpg';
import IndependenceBackupImg from '../../assets/img/lg-whole-home.jpg';
import './Battery.scss';
import ApplianceCheck from './ApplianceCheck';
import { getPrefsDto } from '../../utils';
import { selectUserAddress } from '../../feature/address/address';
import api from '../../services';

const Appliances = [
  'Security System',
  'Internet and Wifi',
  'Home Automation',
  'Refrigerator',
  'Select Outlets',
  'Lighting',
  'Gas Furnace',
  'Gas Water Heater',
  'Garage Door',
  'Washer',
  'Dryer',
  'Window AC',
  'Microwave',
  'Electric Stove',
  'Other',
];

function Battery() {
  const history = useHistory();

  const dispatch = useDispatch();

  const userInfor = useSelector(selectUserInfor);
  const userAddress = useSelector(selectUserAddress);
  const [checkedAppliances, setCheckedAppliances] = useState<string[]>([]);
  const [buttonStatus, setButtonStatus] = useState('');
  const [storagePreference, setStoragePreference] = useState<StoragePreferenceDtoStoragePreferenceEnum>(null as any);

  const handleClick = (e: any) => {
    setStoragePreference(e.target.value);
    setButtonStatus('--continue');
  };

  const handleClickInput = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (buttonStatus === '--continue') {
      dispatch(updateStoragePreferences(storagePreference, checkedAppliances));
      const sourceDto = getPrefsDto(userInfor, userAddress);
      const reqDto: UpdatePreferenceReqDto = {
        ...sourceDto,
        customerPreferencesDetails: {
          motivatorPreference: sourceDto.customerPreferencesDetails.motivatorPreference,
          storagePreference: { storagePreference },
          appliances:
            storagePreference === StoragePreferenceDtoStoragePreferenceEnum.EssentialBackup ? checkedAppliances : [],
        },
      };

      api.visitApi.visitControllerUpdateAndSync(reqDto);
      history.push('/vpp');
    }
  };

  const isChecked = useCallback((appliance: string) => checkedAppliances.includes(appliance), [checkedAppliances]);

  const handleCheck = useCallback((appliance: string, value: boolean) => {
    if (value) {
      setStoragePreference(StoragePreferenceDtoStoragePreferenceEnum.EssentialBackup);
      setCheckedAppliances((v) => [...v, appliance]);
    } else {
      setCheckedAppliances((v) => v.filter((a) => a !== appliance));
    }
    setButtonStatus('--continue');
  }, []);

  return (
    <main className="--main --battery-main --lg">
      <div className="grid-container">
        <h1>What type of battery backup are you looking for?</h1>

        <form id="swell-ecom-tool" className="swell-ecom-tool --grid-mtop">
          <fieldset>
            <div className="grid-row __radio">
              <label className="--battery col-xs-12 col-lg-5">
                <input
                  type="radio"
                  name="battery"
                  checked={storagePreference === StoragePreferenceDtoStoragePreferenceEnum.WholeHomeBackup}
                  value={StoragePreferenceDtoStoragePreferenceEnum.WholeHomeBackup}
                  onClick={handleClick}
                />
                <span className="__card --independence-backup">
                  <img src={IndependenceBackupImg} alt="Whole Home Backup" />
                  <div className="__content">
                    <span className="__title">Whole Home Backup</span>
                    <span className="__txt">
                      Back up your whole home. You might not even be aware that an outage is happening!
                    </span>
                  </div>
                </span>
              </label>
              <label className="--battery col-xs-12 col-lg-7">
                <input
                  type="radio"
                  name="battery"
                  checked={storagePreference === StoragePreferenceDtoStoragePreferenceEnum.EssentialBackup}
                  value={StoragePreferenceDtoStoragePreferenceEnum.EssentialBackup}
                  onClick={handleClick}
                />
                <span className="__card --essential-backup">
                  <img src={EssentialBackupImg} alt="Essential Backup" />
                  <div className="__content">
                    <span className="__title">Essential Backup</span>
                    <span className="__txt">Check all that apply</span>
                    <div className="--appliances-list">
                      {Appliances.map((v) => (
                        <ApplianceCheck key={v} title={v} checked={isChecked(v)} onChange={(e) => handleCheck(v, e)} />
                      ))}
                    </div>
                  </div>
                </span>
              </label>
            </div>
          </fieldset>

          <div className="menu-form">
            <Link className="btn --back" to="preferences">
              <span className="icon-back" />
            </Link>
            <input
              className={`submit btn --submit ${buttonStatus}`}
              type="submit"
              value="Continue"
              onClick={handleClickInput}
            />
            <Link className="btn --restart" to="/">
              <span className="icon-restart" />
            </Link>
          </div>
        </form>
      </div>
    </main>
  );
}
export default Battery;
