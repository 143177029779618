import React, { MouseEvent as ReactMouseEvent, useCallback, useEffect, useState } from 'react';
import { CallOutlined, CalendarMonth } from '@mui/icons-material';

function LGSimpleFooter() {
  const [open, setOpen] = useState(false);

  const phoneRaw = process.env.REACT_APP_CONTACT_PHONE_NUMBER || '';
  const phoneParts = /1?[ -]?\(?(\d{3})\)?[ -]?(\d{3})[ -]?(\d{4})/.exec(phoneRaw);
  let phoneUnformatted = '';
  let phoneFormatted = '';

  if (phoneParts && phoneParts.length > 0) {
    phoneUnformatted = `1${phoneParts[1]}${phoneParts[2]}${phoneParts[3]}`;
    phoneFormatted = `(${phoneParts[1]})\u00A0${phoneParts[2]}\u2011${phoneParts[3]}`;
  }

  useEffect(() => {
    const closeHandler = () => {
      setOpen(false);
    };
    document.addEventListener('click', closeHandler);
    return () => {
      document.removeEventListener('click', closeHandler);
    };
  }, []);

  const handleToggleOpen = useCallback((evt: ReactMouseEvent) => {
    setOpen((v) => !v);
    evt.stopPropagation();
    evt.preventDefault();
  }, []);
  return (
    <>
      <div className={`footer-modal ${open && 'open'}`}>
        <a id="footer_call_link" href={`tel:${phoneUnformatted}`} className="--action-button">
          <CallOutlined />
          <span>Call us at {phoneFormatted}</span>
        </a>
        <a id="footer_meeting_link" href={process.env.REACT_APP_BOOK_APPOINTMENT_LINK} className="--action-button">
          <CalendarMonth />
          <span>Book&nbsp;a&nbsp;meeting to&nbsp;talk&nbsp;later</span>
        </a>
      </div>
      <button id="footer_toggle" className="--toggle-button" type="button" onClick={handleToggleOpen}>
        want
        <mark>Help</mark>
        From an expert energy advisor?
      </button>
    </>
  );
}

export default LGSimpleFooter;
