import React from 'react';
import useIsMobile from '../../hooks/useIsMobile';
import { dedangle } from '../SimpleForm/dedangle';

const text =
  'Unfortunately our current offer requires you to own the property. We will keep you informed of clean energy solutions that fit your criteria.';

function NewThankYouNo() {
  const isMobile = useIsMobile();

  return (
    <main className="--main --simple --center">
      <section>
        <div className="container --small">
          <div className="wrapper --right">
            <h1 className="bigTitle --small">Thank You For Your Interest!</h1>

            <p className="subtitle --center">{isMobile ? text : dedangle(text)}</p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default NewThankYouNo;
