import React from 'react';
import Battery from './components/Battery/Battery';
import BatteryLG from './components/Battery/BatteryLG';
import DogFooter from './components/DogFooter/DogFooter';
import LGFooter from './components/DogFooter/LGFooter';
import EduIntro from './components/EduIntro/EduIntro';
import EduIntroLG from './components/EduIntro/EduIntroLG';
import EduIntroPF from './components/EduIntro/EduIntroPF';
import EduTopics from './components/EduTopics/EduTopics';
import EduTopicsLG from './components/EduTopics/EduTopicsLG';
import LGRecommendation from './components/Recommendation/LGRecommendation';
import Recommendation from './components/Recommendation/Recommendation';
import SwellLogo from './assets/img/logo-swell.svg';
import LGLogo from './assets/img/lg-logo.svg';
import PFLogo from './assets/img/penfed/logo.png';
import EduTopicsPF from './components/EduTopics/EduTopicsPF';
import PFFooter from './components/DogFooter/PFFooter';
import { getContactInfoCompanyName, getContactInfoPrivacyPolicyURL, Theme } from './AppTheme';
import PFRecommendation from './components/Recommendation/PFRecommendation';
import LGThankYou from './components/ThankYou/LGThankYou';
import ThankYou from './components/ThankYou/ThankYou';
import SimpleForm from './components/SimpleForm/SimpleForm';
import LGSimpleFooter from './components/DogFooter/LGSimpleFooter';
import NewThankYouYes from './components/ThankYou/NewThankYouYes';
import NewThankYouNo from './components/ThankYou/NewThankYouNo';

const CompanyName = getContactInfoCompanyName();
const PrivacyPolicy = getContactInfoPrivacyPolicyURL();

export const getIntroPage = () => {
  switch (Theme) {
    case 'LG':
      // Note: Change uncomment below to have LG flow through the "full" process again
      // Also check whether `getFooterShowPopover` should be changed in `AppTheme`.
      // return EduIntroLG;
      return SimpleForm;
    case 'PenFed':
      return EduIntroPF;
    case 'Swell':
    default:
      return EduIntro;
  }
};

export const getEduTopicsPage = () => {
  switch (Theme) {
    case 'LG':
      return EduTopicsLG;
    case 'PenFed':
      return EduTopicsPF;
    case 'Swell':
    default:
      return EduTopics;
  }
};

export const getContactInfoConsentText = () => {
  switch (Theme) {
    case 'LG':
      return (
        <p className="--note">
          I agree to the <a href="http://attn.tv/lg/terms.html">Terms</a> and{' '}
          <a href="https://www.lg.com/us/privacy">Privacy Policy</a> and consent to be contacted by or on behalf of LG
          Electronics U.S.A. Inc. (LG), including by email, calls, and text messages to any telephone number that I
          provide, about my interest in LG products and services. I understand my consent is not required to purchase.
          By clicking continue, I also agree to the{' '}
          <a href="https://www.swellenergy.com/privacy-policy/">Privacy Policy</a> and consent to Swell Energy, LG’s
          approved contractor, reaching out to me on behalf of LG, including by email, calls, and text messages to any
          telephone number that I provide.
        </p>
      );
    case 'PenFed':
      return 'Please contact me regarding financing my solar and storage. *';
    case 'Swell':
    default:
      return 'Please contact me regarding Swell services. *';
  }
};

export const getTermsComponent = () => {
  switch (Theme) {
    case 'LG':
      return null;
    default:
      return (
        <p className="--note --center">
          By submitting this form, I agree to the <a href={PrivacyPolicy}>Privacy Policy</a> and consent to be contacted
          by or on behalf of {CompanyName}, including by email, calls, and text messages to any telephone number that I
          provide, about my interest in {CompanyName} products and services. I understand my consent is not required to
          purchase.
        </p>
      );
  }
};

export const getBatteryPage = () => {
  switch (Theme) {
    case 'LG':
      return BatteryLG;
    case 'PenFed':
    case 'Swell':
    default:
      return Battery;
  }
};

export const getRecommendationPage = () => {
  switch (Theme) {
    case 'LG':
      return LGRecommendation;
    case 'PenFed':
      return PFRecommendation;
    case 'Swell':
    default:
      return Recommendation;
  }
};

export const getThankYouPage = () => {
  // TODO: Uncomment if LG using full flow
  // return LGFooter;
  switch (Theme) {
    case 'LG':
      return NewThankYouYes;
    default:
      return ThankYou;
  }
};

export const getThankYouAltPage = () => {
  // TODO: Uncomment if LG using full flow
  // return LGFooter;
  switch (Theme) {
    case 'LG':
      return NewThankYouNo;
    default:
      return ThankYou;
  }
};

export const getHeaderLogo = () => {
  switch (Theme) {
    case 'LG':
      return LGLogo;
    case 'PenFed':
      return PFLogo;
    case 'Swell':
    default:
      return SwellLogo;
  }
};

export const getFooterComponent = () => {
  switch (Theme) {
    case 'LG':
      // TODO: Uncomment if LG using full flow
      // return LGFooter;
      return LGSimpleFooter;
    case 'PenFed':
      return PFFooter;
    case 'Swell':
    default:
      return DogFooter;
  }
};

export const getHeaderLogoUrl = () => {
  switch (Theme) {
    case 'LG':
      return 'https://www.lg.com/global/business/ess-homeseries-us';
    case 'PenFed':
      return null;
    case 'Swell':
    default:
      return null;
  }
};

export const withoutHeaderPages = ['/lg-lead-compact'];
