import React from 'react';

function dedangle(input: string) {
  const secondLastSpace = input.lastIndexOf(' ', input.lastIndexOf(' ') - 1);
  const first = input.substring(0, secondLastSpace);
  const last = input.substring(secondLastSpace + 1);
  return (
    <>
      <span>{first}</span> <span style={{ display: 'inline-block' }}>{last}</span>
    </>
  );
}
export { dedangle };
