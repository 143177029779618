import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { selectUserInfor, updateStoragePreferences } from '../../feature/eduIntro/eduIntro';
import { StoragePreferenceDtoStoragePreferenceEnum } from '../../services/api';
import EssentialBackupImg from '../../assets/img/essential.jpg';
import IndependenceBackupImg from '../../assets/img/independence.jpg';
import NotSureImg from '../../assets/img/notsure.jpg';
import './Battery.scss';
import { getShouldPromptForVPP } from '../../AppTheme';

function Battery() {
  const history = useHistory();

  const dispatch = useDispatch();

  const nextPage = getShouldPromptForVPP() ? '/vpp' : '/calculating';
  const userInfo = useSelector(selectUserInfor);
  const [buttonStatus, setButtonStatus] = useState('');
  const [storagePreference, setStoragePreference] = useState<StoragePreferenceDtoStoragePreferenceEnum>(null as any);

  const handleClick = (e: any) => {
    setStoragePreference(e.target.value);
    setButtonStatus('--continue');
  };

  const notInterested = () => {
    setStoragePreference(StoragePreferenceDtoStoragePreferenceEnum.ImNotInterested);
    dispatch(updateStoragePreferences(StoragePreferenceDtoStoragePreferenceEnum.ImNotInterested, []));
    history.push(nextPage);
  };

  const handleClickInput = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (buttonStatus === '--continue') {
      dispatch(updateStoragePreferences(storagePreference, []));
      history.push(nextPage);
    }
  };
  return (
    <main className="--main --battery-main">
      <div className="grid-container">
        <h1>What type of battery backup are you looking for?</h1>

        <form id="swell-ecom-tool" className="swell-ecom-tool --grid-mtop">
          <fieldset>
            <div className="grid-row __radio">
              <label className="--battery col-xs-12 col-md-4">
                <input
                  type="radio"
                  name="battery"
                  value={StoragePreferenceDtoStoragePreferenceEnum.EssentialBackup}
                  onClick={handleClick}
                />
                <span className="__card --essential-backup">
                  <img src={EssentialBackupImg} alt="Essential Backup" />
                  <div className="__content">
                    <span className="__title">Essential Backup</span>
                    <span className="__txt">
                      The basics to keep your food fresh, your home warm - or cool, water flowing, and a few
                      conveniences.
                    </span>
                  </div>
                </span>
              </label>
              <label className="--battery col-xs-12 col-md-4">
                <input
                  type="radio"
                  name="battery"
                  value={StoragePreferenceDtoStoragePreferenceEnum.WholeHomeBackup}
                  onClick={handleClick}
                />
                <span className="__card --independence-backup">
                  <img src={IndependenceBackupImg} alt="Independence Backup" />
                  <div className="__content">
                    <span className="__title">Independence Backup</span>
                    <span className="__txt">You might not even be aware that an outage is happening!</span>
                  </div>
                </span>
              </label>
              <label className="--battery col-xs-12 col-md-4">
                <input
                  type="radio"
                  name="battery"
                  value={StoragePreferenceDtoStoragePreferenceEnum.ImNotSure}
                  onClick={handleClick}
                />
                <span className="__card --not-sure">
                  <img src={NotSureImg} alt="Not Sure" />
                  <div className="__content">
                    <span className="__title">I’m not sure</span>
                    <span className="__txt">Help me decide, I’m not sure which option is right for me.</span>
                  </div>
                </span>
              </label>
            </div>
          </fieldset>

          {!userInfo.isSolarAlreadyInstalled && (
            <div className="grid-row --justify-center">
              <button className="__not-interested-button" type="button" onClick={() => notInterested()}>
                I’m not interested in battery backup right now, show me just solar
              </button>
            </div>
          )}

          <div className="menu-form">
            <Link className="btn --back" to="preferences">
              <span className="icon-back" />
            </Link>
            <input
              className={`submit btn --submit ${buttonStatus}`}
              type="submit"
              value="Continue"
              onClick={handleClickInput}
            />
            <Link className="btn --restart" to="/">
              <span className="icon-restart" />
            </Link>
          </div>
        </form>
      </div>
    </main>
  );
}
export default Battery;
